<div class="h-100 overflow-hidden">
    <div class="d-flex justify-content-between align-items-center pt-2">
        <h2 class="mb-0 mobile-filter-activity-title">Activity Filter</h2>
        <button class="btn" (click)="close()"><img src="/assets/icons/close.svg" alt="close icon"></button>
    </div>
    <hr>
    <div class="mobile-filter-content" #dialogContent>
        <div *ngIf="active_category_type === 'rafting' || active_category === 'rafting'">
            <form (submit)="submitRaftingMerchants()" [formGroup]="raftingForm">
                <div class="row px-2">
                  <div class="col-2 my-auto">
                    <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
                  </div>
                  <div class="col-10">
                    <label class="filter-label" for="">Date</label>
                    <input type="text" class="filter-input form-control datepicker px-0" (keydown)="preventToggle($event)" formControlName="date" placeholder="Date"
                      [min]="startDate" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
                  </div>
                  <hr class="filter-hr">
                  <div class="col-2 my-auto">
                    <img src="/assets/filter-icons/time-icon.svg" alt="time-icon" />
                  </div>
                  <div class="col-10">
                    <label class="filter-label" for="">Distance</label>
                    <select name="book-time" id="distance" class="filter-input form-control px-0"  (change)="onChangeRaftingValues()"   formControlName="distance"
                      (click)="preventToggle($event)">
                      <option value="">Select</option>
                      <option value="9 Km">9 Km</option>
                      <option value="16 Km">16 Km</option>
                      <option value="26 Km">26 Km</option>
                      <option value="35 Km">35 Km</option>
                    </select>
                  </div>
                  <hr class="filter-hr">
                  <div class="col-2 my-auto">
                    <img src="/assets/filter-icons/location-icon.svg" alt="location-icon" />
                  </div>
                  <div class="col-10">
                    <label class="filter-label" for="">Time</label>
                    <select name="book-time" id="book-time" class="filter-input form-control px-0" formControlName="time" (click)="preventToggle($event)">
                      <option value="">Select</option>
                      <option *ngFor="let timing of newTimings" [disabled]="isPastDateTime(timing)">{{ timing }}
                      </option>
                    </select>
                  </div>
                  <hr class="filter-hr">
                  <div class="col-2 my-auto">
                    <img src="/assets/filter-icons/guest-icon.svg" alt="guest-icon" />
                  </div>
                  <div class="col-10">
                    <label class="filter-label" for="">Guests</label>
                    <div class="input-group guest-input mt-2">
                      <span class="input-group-btn py-auto px-2 pointer" (click)="decreaseAdultValue('rafting')"
                        (click)="preventToggle($event)">
                        <img class="dec-img" src="/assets/minus.svg" width="20px" alt="minus" />
                      </span>
                      <input type="text" class="form-control input-number text-center font-size-14px input-guest" readonly
                        formControlName="guest" [(ngModel)]="raftingAdultsCount" min="1" max="9">
                      <span class="input-group-btn  py-auto px-2 pointer" (click)="increaseAdultValue('rafting')"
                        (click)="preventToggle($event)">
                        <img class="plus-img" src="/assets/plus.svg" width="20px" alt="plus" />
                      </span>
                    </div>
                    <div *ngIf="isSubmittingRafting && r['guest'].errors" class="invalid-feedback">
                      <div *ngIf="r['guest'].errors['pattern']">Guests should not be Zero & not be greater than 9.</div>
                      <div *ngIf="r['guest'].errors['required']">No of adult is required</div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit" class="btn search-btn px-5 text-center mx-auto rounded-pill border-0 my-4">Search</button>
                  </div>
                  <hr class="filter-hr">
                  <div class="col-2 my-auto">
                    <img src="/assets/filter-icons/filter-icon.svg" alt="filter-icon" />
                  </div>
                  <div class="col-10">
                    <h3 class="mb-0 filter-title">Filter <img src="/assets/filter-icons/down-arrow.svg" (click)="toggleFilter('rafting')" class="ms-3 pointer" alt="down-arrow-icon" /></h3>
                  </div>
                  <div [class.d-none]="!rafting_filter_visible">
                    <h5 class="filter-content-title mt-3">Price range</h5>
                    <div class="w-100 px-3">
                      <mat-slider class="mx-auto home-range-slider" [min]="getRangePrice('min')" [max]="getRangePrice('max')" >
                        <input [value]="getRangePrice('min')"  matSliderStartThumb (change)="changeRangePrice('rafting-min-price', $event)" />
                        <input [value]="getRangePrice('max')"  matSliderEndThumb  (change)="changeRangePrice('rafting-max-price', $event)" />
                      </mat-slider>
                      <div class="d-flex justify-content-between mx-2"><h6 class="filter-range-price" >Min: {{selectedRaftingMinPrice}}</h6> <h6 class="filter-range-price">Max: {{selectedRaftingMaxPrice}}</h6></div>
                    </div>
                    <h5 class="filter-content-title mt-3">Rating</h5>
                    <div class="ms-3">
                      <div class="form-check">
                        <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchRaftingMerchants()" id="mobile-filter-rafting-rating-5">
                        <div class="filter-rating-star">
                          <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                            <span class="rating-star-filled">&#9733;</span>
                          </ng-container>
                        </div>
                      </div>
                      <div class="form-check mt-3">
                        <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchRaftingMerchants()" id="mobile-filter-rafting-rating-4">
                        <div class="filter-rating-star">
                          <ng-container *ngFor="let star of [1, 2, 3, 4]">
                            <span class="rating-star-filled">&#9733;</span>
                          </ng-container>
                        </div>
                      </div>
                      <div class="form-check mt-3">
                        <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchRaftingMerchants()" id="mobile-filter-rafting-rating-3">
                        <div class="filter-rating-star">
                          <ng-container *ngFor="let star of [1, 2, 3]">
                            <span class="rating-star-filled">&#9733;</span>
                          </ng-container>
                        </div>
                      </div>
                      <div class="form-check mt-3">
                        <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchRaftingMerchants()" id="mobile-filter-rafting-rating-2">
                        <div class="filter-rating-star">
                          <ng-container *ngFor="let star of [1, 2]">
                            <span class="rating-star-filled">&#9733;</span>
                          </ng-container>
                        </div>
                      </div>
                      <div class="form-check mt-3">
                        <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchRaftingMerchants()" id="mobile-filter-rafting-rating-1">
                        <div class="filter-rating-star">
                          <ng-container *ngFor="let star of [1]">
                            <span class="rating-star-filled">&#9733;</span>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
        </div>
        <div  *ngIf="active_category_type === 'activity' || active_category === 'activity'">
          <form  (submit)="searchActivityMerchants()" [formGroup]="activitiesForm">
            <div class="row px-2">
              <div class="col-3 text-center my-auto">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-9">
                <label class="filter-label" for="">Date</label>
                <input type="text" class="filter-input form-control datepicker px-0" (keydown)="preventToggle($event)" formControlName="date" placeholder="Date"
                  [min]="startDate" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
              </div>
              <hr class="filter-hr">
              <div class="col-3 text-center my-auto">
                <img src="/assets/filter-icons/time-icon.svg" alt="time-icon" />
              </div>
              <div class="col-9">
                <label class="filter-label" for="">Time</label>
                <select name="book-time" id="book-time" class="filter-input form-control px-0" formControlName="time" (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let timing of newTimings" [disabled]="isPastDateTime(timing)">{{ timing }}
                  </option>
                </select>
              </div>
              <hr class="filter-hr">
              <div class="col-3 text-center my-auto">
                <img src="/assets/filter-icons/activity-icon.svg" alt="location-icon" />
              </div>
              <div class="col-9">
                <label class="filter-label" for="">Activity</label>
                <select name="" id="activitySelect" class="filter-input form-control px-0"
                formControlName="activities" (change)="changeActivities($event.target.value)">
                <option value="">select</option>
                <option *ngFor="let activity of quickActivityTypes" [value]="activity.activitytypeid">
                  {{activity.activitytypemastername}}</option>
              </select>
              </div>
              <div class="text-center mt-3">
                <button type="submit" class="btn search-btn px-5 text-center mx-auto rounded-pill border-0 ">Search</button>
              </div>
              <hr class="filter-hr">
              <div class="col-3 text-center my-auto">
                <img src="/assets/filter-icons/filter-icon.svg" alt="filter-icon" />
              </div>
              <div class="col-9">
                <h3 class="mb-0 filter-title">Filter <img src="/assets/filter-icons/down-arrow.svg" (click)="toggleFilter('activity')" class="ms-3 pointer" alt="down-arrow-icon" /></h3>
              </div>
              <div [class.d-none]="!activity_filter_visible">
                <h5 class="filter-content-title mt-3">Price range</h5>
                <div class="w-100">
                  <mat-slider class="mx-auto home-range-slider" [min]="getRangePrice('min')" [max]="getRangePrice('max')" >
                    <input [value]="getRangePrice('min')"  matSliderStartThumb (change)="changeRangePrice('quickactivities-min-price', $event)" />
                    <input [value]="getRangePrice('max')"  matSliderEndThumb  (change)="changeRangePrice('quickactivities-max-price', $event)" />
                  </mat-slider>
                  <div class="d-flex justify-content-between mx-2"><h6 class="filter-range-price" >Min: {{selectedQuickActivitiesMinPrice}}</h6> <h6 class="filter-range-price">Max: {{selectedQuickActivitiesMaxPrice}}</h6></div>
                </div>
                <h5 class="filter-content-title mt-3">Rating</h5>
                <div>
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitquickActivities()" id="mobile-filter-quickactivities-rating-5">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitquickActivities()" id="mobile-filter-quickactivities-rating-4">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitquickActivities()" id="mobile-filter-quickactivities-rating-3">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox"  (change)="submitquickActivities()" id="mobile-filter-quickactivities-rating-2">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitquickActivities()" id="mobile-filter-quickactivities-rating-1">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div  *ngIf="active_category_type === 'camping' || active_category === 'camping'">
          <form (submit)="submitCampingFilter()" class="camping-form  mx-md-0" [formGroup]="campingForm">
            <div class="row px-2">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Check-In</label>
                <input type="text" class="filter-input form-control datepicker px-0" (keydown)="preventToggleDefault($event)" formControlName="date" placeholder="Date"
                  [min]="alignDate(startDate)" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
              </div>
              <hr class="filter-hr">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Check-Out</label>
                <input type="text" class="filter-input form-control datepicker1 px-0" (keydown)="preventToggleDefault($event)" formControlName="checkoutdate" placeholder="Date"
                  [min]="alignDate(startDate)" [(ngModel)]="currentDate1" (click)="preventToggle($event)" />
              </div>
              <hr class="filter-hr">
              <ng-container formArrayName="variations">
                <div class="row"   *ngFor="let variation of campingVariationsArrayControls; let i = index" [formGroupName]="i">
                  <div class="col-2 my-auto">
                    <img src="/assets/icons/camping-variation.svg" alt="variation-icon" />
                  </div>
                  <div class="col-10">
                    <label class="filter-label" for="">{{variations[i].variations.type}}</label>
                    <select name="book-variation" id="book-variation" class="filter-input form-control px-0" formControlName="variationvalueid" (click)="preventToggle($event)">
                      <option value="">Select</option>
                      <option *ngFor="let variationValue of variationValues[i]"
                      [value]="variationValue.variationvalues.id">
                      {{variationValue.variationvalues.value }}</option>
                    </select>
                  </div>
                  <hr class="filter-hr">
                </div>
              </ng-container>
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/guest-icon.svg" alt="guest-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Adults</label>
                <div class="input-group guest-input mt-2">
                  <span class="input-group-btn py-auto px-2 pointer" (click)="decreaseValue('adult')"
                    (click)="preventToggle($event)">
                    <img class="dec-img" src="/assets/minus.svg" width="20px" alt="minus" />
                  </span>
                  <input type="text" class="form-control input-number text-center font-size-14px input-guest" readonly
                    formControlName="adult" [(ngModel)]="adultsCount" min="1" max="9">
                  <span class="input-group-btn  py-auto px-2 pointer" (click)="increaseValue('adult')"
                    (click)="preventToggle($event)">
                    <img class="plus-img" src="/assets/plus.svg" width="20px" alt="plus" />
                  </span>
                </div>
              </div>
              <hr class="filter-hr">
              <input type="text" class="form-control  text-center input-bg-color guest-user-select d-none" 
              style="background-color: white;" readonly formControlName="guest" [(ngModel)]="totalGuest"
              (click)="preventToggle($event)" />
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/guest-icon.svg" alt="guest-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Children</label>
                <div class="input-group guest-input mt-2">
                  <span class="input-group-btn py-auto px-2 pointer" (click)="decreaseValue('children')"
                    (click)="preventToggle($event)">
                    <img class="dec-img" src="/assets/minus.svg" width="20px" alt="minus" />
                  </span>
                  <input type="text" class="form-control input-number text-center font-size-14px input-guest" readonly
                    formControlName="children" [(ngModel)]="childrenCount" min="1" max="10">
                  <span class="input-group-btn  py-auto px-2 pointer" (click)="increaseValue('children')"
                    (click)="preventToggle($event)">
                    <img class="plus-img" src="/assets/plus.svg" width="20px" alt="plus" />
                  </span>
                </div>
              </div>
              <hr class="filter-hr">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/filter-icon.svg" alt="filter-icon" />
              </div>
              <div class="col-10">
                <h3 class="mb-0 filter-title">Filter <img src="/assets/filter-icons/down-arrow.svg" (click)="toggleFilter('camping')" class="ms-3 pointer" alt="down-arrow-icon" /></h3>
              </div>
              <div [class.d-none]="!camping_filter_visible">
                <h5 class="filter-content-title mt-3">Price range</h5>
                <div class="w-100 px-3">
                  <mat-slider class="mx-auto home-range-slider" [min]="getRangePrice('min')" [max]="getRangePrice('max')" >
                    <input [value]="getRangePrice('min')"  matSliderStartThumb (change)="changeRangePrice('camping-min-price', $event)" />
                    <input [value]="getRangePrice('max')"  matSliderEndThumb  (change)="changeRangePrice('camping-max-price', $event)" />
                  </mat-slider>
                  <div class="d-flex justify-content-between mx-2"><h6 class="filter-range-price" >Min: {{selectedCampingMinPrice}}</h6> <h6 class="filter-range-price">Max: {{selectedCampingMaxPrice}}</h6></div>
                </div>
                <h5 class="filter-content-title mt-3">Rating</h5>
                <div class="ms-3">
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchCamping()" id="mobile-filter-camping-rating-5">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchCamping()" id="mobile-filter-camping-rating-4">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchCamping()" id="mobile-filter-camping-rating-3">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchCamping()" id="mobile-filter-camping-rating-2">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="searchCamping()" id="mobile-filter-camping-rating-1">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
                <button type="submit" class="btn search-btn px-5 text-center mx-auto rounded-pill border-0 my-4">Search</button>
            </div>
          </form>
        </div>
        <div *ngIf="active_category_type === 'trekking' || active_category === 'trekking'">
          <h3 class="text-center"  [class.hidden]="!loadingMonths && months.length != 0 " >Currently no slots available!</h3>
          <form (submit)="submitTrekkingFilter()" class="camping-form  mx-md-0" [formGroup]="trekkingForm">
            <div class="row px-2">
              <div class="col-2 my-auto" [class.d-none]="!loadingMonths && months.length == 0">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10" [class.d-none]="!loadingMonths && months.length == 0">
                <label class="filter-label" for="">Month</label>
                <select name="book-variation" id="book-variation" class="filter-input form-control px-0" formControlName="month"  (change)="changeMonths()" (click)="preventToggle($event)">
                  <option value="">Month</option>
                  <option *ngFor="let month of months">{{ month }}</option>
                </select>
              </div>
              <hr class="filter-hr" [class.d-none]="!loadingMonths && months.length == 0">
              <div class="col-2 my-auto" [class.d-none]="!loadingMonths && months.length == 0">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10" [class.d-none]="!loadingMonths && months.length == 0">
                <label class="filter-label" for="">Available Slots</label>
                <select name="book-variation" id="book-variation" class="filter-input form-control px-0" formControlName="slot" (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let slot of slots">{{ slot }}</option>
                </select>
              </div>
              <hr class="filter-hr">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/filter-icon.svg" alt="filter-icon" />
              </div>
              <div class="col-10">
                <h3 class="mb-0 filter-title">Filter <img src="/assets/filter-icons/down-arrow.svg" (click)="toggleFilter('trekking')" class="ms-3 pointer" alt="down-arrow-icon" /></h3>
              </div>
              <div [class.d-none]="!trekking_filter_visible">
                <h5 class="filter-content-title mt-3">Price range</h5>
                <div class="w-100 px-3">
                  <mat-slider class="mx-auto home-range-slider" [min]="getRangePrice('min')" [max]="getRangePrice('max')" >
                    <input [value]="getRangePrice('min')"  matSliderStartThumb (change)="changeRangePrice('trekking-min-price', $event)" />
                    <input [value]="getRangePrice('max')"  matSliderEndThumb  (change)="changeRangePrice('trekking-max-price', $event)" />
                  </mat-slider>
                  <div class="d-flex justify-content-between mx-2"><h6 class="filter-range-price" >Min: {{selectedTrekkingMinPrice}}</h6> <h6 class="filter-range-price">Max: {{selectedTrekkingMaxPrice}}</h6></div>
                </div>
                <h5 class="filter-content-title mt-3">Rating</h5>
                <div class="ms-3">
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTrekking()" id="mobile-filter-trekking-rating-5">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTrekking()" id="mobile-filter-trekking-rating-4">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTrekking()" id="mobile-filter-trekking-rating-3">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTrekking()" id="mobile-filter-trekking-rating-2">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTrekking()" id="mobile-filter-trekking-rating-1">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
                <button type="submit" [class.d-none]="!loadingMonths && months.length == 0" class="btn search-btn px-5 text-center mx-auto rounded-pill border-0 my-4">Search</button>
            </div>
          </form>
        </div>
        <div *ngIf="active_category_type === 'tour' || active_category === 'tour'">
          <h3 class="text-center"  [class.hidden]="!loadingMonths && months.length != 0 " >Currently no slots available!</h3>
          <form (submit)="submitTourFilter()" class="camping-form  mx-md-0" [formGroup]="biketourForm">
            <div class="row px-2">
              <div class="col-2 my-auto"  [class.d-none]="!loadingMonths && months.length == 0">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10"  [class.d-none]="!loadingMonths && months.length == 0">
                <label class="filter-label" for="">Month</label>
                <select name="book-variation" id="book-variation" class="filter-input form-control px-0" formControlName="month"  (change)="changeMonths()" (click)="preventToggle($event)">
                  <option value="">Month</option>
                  <option *ngFor="let month of months">{{ month }}</option>
                </select>
              </div>
              <hr class="filter-hr"  [class.d-none]="!loadingMonths && months.length == 0">
              <div class="col-2 my-auto"  [class.d-none]="!loadingMonths && months.length == 0">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10"  [class.d-none]="!loadingMonths && months.length == 0">
                <label class="filter-label" for="">Available Slots</label>
                <select name="book-variation" id="book-variation" class="filter-input form-control px-0" formControlName="slot" (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let slot of slots">{{ slot }}</option>
                </select>
              </div>
              <hr class="filter-hr"  [class.d-none]="!loadingMonths && months.length == 0">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/filter-icon.svg" alt="filter-icon" />
              </div>
              <div class="col-10">
                <h3 class="mb-0 filter-title">Filter <img src="/assets/filter-icons/down-arrow.svg" (click)="toggleFilter('tour')" class="ms-3 pointer" alt="down-arrow-icon" /></h3>
              </div>
              <div [class.d-none]="!biketour_filter_visible">
                <h5 class="filter-content-title mt-3">Price range</h5>
                <div class="w-100 px-3">
                  <mat-slider class="mx-auto home-range-slider" [min]="getRangePrice('min')" [max]="getRangePrice('max')" >
                    <input [value]="getRangePrice('min')"  matSliderStartThumb (change)="changeRangePrice('tour-min-price', $event)" />
                    <input [value]="getRangePrice('max')"  matSliderEndThumb  (change)="changeRangePrice('tour-max-price', $event)" />
                  </mat-slider>
                  <div class="d-flex justify-content-between mx-2"><h6 class="filter-range-price" >Min: {{selectedBikeTourMinPrice}}</h6> <h6 class="filter-range-price">Max: {{selectedBikeTourMaxPrice}}</h6></div>
                </div>
                <h5 class="filter-content-title mt-3">Rating</h5>
                <div class="ms-3">
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTour()" id="mobile-filter-tour-rating-5">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTour()" id="mobile-filter-tour-rating-4">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTour()" id="mobile-filter-tour-rating-3">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTour()" id="mobile-filter-tour-rating-2">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTour()" id="mobile-filter-tour-rating-1">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
                <button type="submit" class="btn search-btn px-5 text-center mx-auto rounded-pill border-0 my-4">Search</button>
            </div>
          </form>
        </div>
        <div *ngIf="active_category_type === 'topAdventurists' || active_category === 'topAdventurists'">
          <form (submit)="submitTopAdventuristsFilter()" class="camping-form  mx-md-0" [formGroup]="topAdventuristsForm">
            <div class="row px-2">
              <div class="col-12">
                <label class="mobile-top-adventurists-filter-label mb-2" for="">Search by name</label>
                <div class="input-group mb-3 rounded-pill">
                  <input type="text" class="form-control mobile-filter-topadventurists-input bg-white" placeholder="search by name" (click)="preventToggle($event)" formControlName="name" />
                  <span class="input-group-text  mobile-filter-topadventurists-input-icon bg-white border-0" id="adventurists-addon"><img src="/assets/search-icon.svg" width="15px" alt="Image"></span>
                </div>
              </div>
              <hr class="filter-hr">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/filter-icon.svg" alt="filter-icon" />
              </div>
              <div class="col-10">
                <h3 class="mb-0 filter-title">Filter <img src="/assets/filter-icons/down-arrow.svg" (click)="toggleFilter('topadventurists')" class="ms-3 pointer" alt="down-arrow-icon" /></h3>
              </div>
              <div [class.d-none]="!topadventurists_filter_visible">
                <h5 class="filter-content-title mt-3">Rating</h5>
                <div class="ms-3">
                  <div class="form-check">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTopAdventurists()" id="mobile-filter-topadventurists-rating-5">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTopAdventurists()" id="mobile-filter-topadventurists-rating-4">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3, 4]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTopAdventurists()" id="mobile-filter-topadventurists-rating-3">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2, 3]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTopAdventurists()" id="mobile-filter-topadventurists-rating-2">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1, 2]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check mt-3">
                    <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitTopAdventurists()" id="mobile-filter-topadventurists-rating-1">
                    <div class="filter-rating-star">
                      <ng-container *ngFor="let star of [1]">
                        <span class="rating-star-filled">&#9733;</span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
                <button type="submit" class="btn search-btn px-5 text-center mx-auto rounded-pill border-0 my-4">Search</button>
            </div>
          </form>
        </div>
        <div *ngIf="active_category_type === 'bikerental' || active_category === 'bikerental'">
          <form (submit)="submitBikeRentalsFilter()" class="camping-form  mx-md-0" [formGroup]="rentalForm">
            <div class="row px-2">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Pickup Date</label>
                <input type="text" class="filter-input form-control datepicker px-0" (keydown)="preventToggleDefault($event)" formControlName="date" placeholder="Date"
                  [min]="alignDate(startDate)" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
              </div>
              <hr class="filter-hr">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/date-icon.svg" alt="date-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Dropoff Date</label>
                <input type="text" class="filter-input form-control datepicker1 px-0" (keydown)="preventToggleDefault($event)" formControlName="dropdowndate" placeholder="Date"
                  [min]="alignDate(startDate)" [(ngModel)]="currentDate" (click)="preventToggle($event)" />
              </div>
              <hr class="filter-hr">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/time-icon.svg" alt="time-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Pickup Time</label>
                <select name="book-time" id="book-time" class="filter-input form-control px-0" formControlName="time" (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let timing of newTimings" [disabled]="isPastDateTime(timing)">{{ timing }}
                  </option>
                </select>
              </div>
              <hr class="filter-hr">
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/time-icon.svg" alt="time-icon" />
              </div>
              <div class="col-10">
                <label class="filter-label" for="">Dropoff Time</label>
                <select name="book-time" id="book-time" class="filter-input form-control px-0" formControlName="dropdowntime" (click)="preventToggle($event)">
                  <option value="">Select</option>
                  <option *ngFor="let timing of newTimings" [disabled]="isPastDateTime(timing)">{{ timing }}
                  </option>
                </select>
              </div>
              <hr class="filter-hr">
              <ng-container formArrayName="variations">
                <div class="row"   *ngFor="let variation of rentalVariationsArrayControls; let i = index" [formGroupName]="i">
                  <div class="col-2 my-auto">
                    <img src="/assets/filter-icons/rental-variation.svg" alt="variation-icon" />
                  </div>
                  <div class="col-10">
                    <label class="filter-label" for="">{{variations[i].variations.type}}</label>
                    <select name="book-variation" id="book-variation" class="filter-input form-control px-0" formControlName="variationvalueid" (change)="changeRentalDefaultVariation(i)" (click)="preventToggle($event)">
                      <option value="">Select</option>
                      <option *ngFor="let variationValue of variationValues[i]"
                      [value]="variationValue.variationvalues.id">
                      {{variationValue.variationvalues.value }}</option>
                    </select>
                  </div>
                  <hr class="filter-hr">
                </div>
              </ng-container>
              <div class="col-2 my-auto">
                <img src="/assets/filter-icons/filter-icon.svg" alt="filter-icon" />
              </div>
              <div class="col-10">
                <h3 class="mb-0 filter-title">Filter <img src="/assets/filter-icons/down-arrow.svg" (click)="toggleFilter('bikerental')" class="ms-3 pointer" alt="down-arrow-icon" /></h3>
              </div>
              <div [class.d-none]="!bikerentals_filter_visible">
                <h5 class="filter-content-title mt-3">Price range</h5>
                <div class="w-100 px-3">
                  <mat-slider class="mx-auto home-range-slider" [min]="getRangePrice('min')" [max]="getRangePrice('max')">
                    <input [value]="getRangePrice('min')"  matSliderStartThumb (change)="changeRangePrice('bikerentals-min-price', $event)" />
                    <input [value]="getRangePrice('max')"  matSliderEndThumb  (change)="changeRangePrice('bikerentals-max-price', $event)" />
                  </mat-slider>
                  <div class="d-flex justify-content-between mx-2"><h6 class="filter-range-price" >Min: {{selectedBikeRentalsMinPrice}}</h6> <h6 class="filter-range-price">Max: {{selectedBikeRentalsMaxPrice}}</h6></div>
                </div>
              </div>
                <button type="submit" class="btn search-btn px-5 text-center mx-auto rounded-pill border-0 my-4">Search</button>
            </div>
          </form>
        </div>
        <div *ngIf="active_category != '' && active_category != 'topAdventurists' && active_category != 'activity' && active_category != 'rafting' && active_category != 'trekking' && active_category != 'camping' && active_category != 'bikerental' && active_category != 'tour' && active_category_type === ''" class="row px-2">
          <div class="row px-2">
            <div>
              <h5 class="filter-content-title mt-3">Price range</h5>
              <div class="w-100 px-3">
                <mat-slider class="mx-auto home-range-slider" [min]="getRangePrice('min')" [max]="getRangePrice('max')" >
                  <input [value]="getRangePrice('min')"  matSliderStartThumb (change)="changeRangePrice('category-min-price', $event)" />
                  <input [value]="getRangePrice('max')"  matSliderEndThumb  (change)="changeRangePrice('category-max-price', $event)" />
                </mat-slider>
                <div class="d-flex justify-content-between mx-2"><h6 class="filter-range-price" >Min: {{selectedCategoryMinPrice}}</h6> <h6 class="filter-range-price">Max: {{selectedCategoryMaxPrice}}</h6></div>
              </div>
              <h5 class="filter-content-title mt-3">Rating</h5>
              <div class="ms-3">
                <div class="form-check">
                  <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitCategoryFilter()" id="mobile-filter-category-rating-5">
                  <div class="filter-rating-star">
                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                      <span class="rating-star-filled">&#9733;</span>
                    </ng-container>
                  </div>
                </div>
                <div class="form-check mt-3">
                  <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitCategoryFilter()" id="mobile-filter-category-rating-4">
                  <div class="filter-rating-star">
                    <ng-container *ngFor="let star of [1, 2, 3, 4]">
                      <span class="rating-star-filled">&#9733;</span>
                    </ng-container>
                  </div>
                </div>
                <div class="form-check mt-3">
                  <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitCategoryFilter()" id="mobile-filter-category-rating-3">
                  <div class="filter-rating-star">
                    <ng-container *ngFor="let star of [1, 2, 3]">
                      <span class="rating-star-filled">&#9733;</span>
                    </ng-container>
                  </div>
                </div>
                <div class="form-check mt-3">
                  <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitCategoryFilter()" id="mobile-filter-category-rating-2">
                  <div class="filter-rating-star">
                    <ng-container *ngFor="let star of [1, 2]">
                      <span class="rating-star-filled">&#9733;</span>
                    </ng-container>
                  </div>
                </div>
                <div class="form-check mt-3">
                  <input class="form-check-input filter-checkbox" type="checkbox" (change)="submitCategoryFilter()" id="mobile-filter-category-rating-1">
                  <div class="filter-rating-star">
                    <ng-container *ngFor="let star of [1]">
                      <span class="rating-star-filled">&#9733;</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
    </div>
</div>