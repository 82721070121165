import { ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';
import { AppService } from './app.service';
import { NavigationEnd, Router } from '@angular/router';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './data.service';
import { AuthService } from './auth/auth.service';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Geolocation } from '@capacitor/geolocation';
declare var $: any;
declare var locationBg : any;
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Advenzone';
  userAgent: string = "";
  additionalImagesVisible: boolean = false;
  datafrom: any;
  name: any;
  merchantId:any;
  activityType: any;
  showCallback: boolean = true;
  isScrolled = false;
  // showLabel: boolean = false;
  // hovering: boolean = false;
  // showCallLabel: boolean = false;
  // hoveringCall: boolean = false;
  currentUrl: any;
  active: any;
  // animateTada: boolean = false;
  // animateCallTada: boolean = false;
  // animateImgTada: boolean = true; 
  // animateLabelTada: boolean = true;
  // animateCallImgTada: boolean = true; 
  // animateCallLabelTada: boolean = true;
  latitude:any =0;
  longitude:any =0;
  showMenu: boolean = false;

  constructor(private appService: AppService, private datePipe: DatePipe,  public meta: Meta, private http: HttpClient, private authService: AuthService, private router: Router, private dialog: MatDialog, public dataService: DataService, private auth: AuthService, private cdr: ChangeDetectorRef,private elementRef: ElementRef) {
    this.userAgent = window.navigator.userAgent;
    locationBg = this;

    this.currentUrl = this.router.url;
    this.auth.UserType.subscribe(type => {
      this.active = type
      if (type !== null && type !== 'user') {
        this.showCallback = false;
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
          if (this.active) {
            this.showCallback = false;
          }
        })
      }
      else {
      }

    });
    if(!environment.production){
      this.meta.addTag({ name: 'robots', content: 'noindex' });
      this.meta.addTag({ name: 'googlebot', content: 'noindex' });
    }
    // document.body.addEventListener('click', this.handleClickOutside.bind(this));
  }
  ngOnInit() {

    const printCurrentPosition = async () => {
      const coordinates:any = await Geolocation.getCurrentPosition();
      this.appService.userLocation.next({
        latitude: coordinates.coords.latitude,
        longitude: coordinates.coords.longitude
      })
      console.log('Latitudes: ' + coordinates.coords.latitude + '\n' +
        'Longitude: ' + coordinates.coords.longitude + '\n' +
        'Altitude: ' + coordinates.coords.altitude + '\n' +
        'Accuracy: ' + coordinates.coords.accuracy + '\n' +
        'Altitude Accuracy: ' + coordinates.coords.altitudeAccuracy + '\n' +
        'Heading: ' + coordinates.coords.heading + '\n' +
        'Speed: ' + coordinates.coords.speed + '\n' +
        'Timestamp: ' + coordinates.timestamp + '\n');
    };
    
    if(this.ifMobileApp()){
       printCurrentPosition();
    }
    const userType = localStorage.getItem('UserType');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      $("#ui-datepicker-div").css('display', 'none')
      const urlSegments = event.url.split('?')[0];
      if (this.isCallbackUrl(urlSegments)) {
        this.showCallback = false;
      }
      else if (userType !== 'user' && userType !== null) {
        this.showCallback = false;
      }
      else {
        this.showCallback = true;
      }
      // if (urlSegments === '/contact-us') {
        
      //   this.showCallback = true;
      //   const callbackLabel = document.getElementById('callback');
      //   if (callbackLabel) {
      //     callbackLabel.style.display = 'none';
      //   }
      // } else {
      //   const callbackLabel = document.getElementById('callback');
      //   if (callbackLabel) {
      //     callbackLabel.style.display = 'block';
      //   }
      // }
    });

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   if (event.url === '/blogs' || event.url === '//merchant-reviews' || event.url === '/404-not-found') {
    //     this.showCallback = false;
    //   } else {
    //     this.showCallback = true;
    //   }
    // }); this.auth.UserType.subscribe(type => {
    //   if ( type !== 'user') {
    //     this.showCallback = false;
    //     console.log( "if",  this.showCallback);
    //   console.log("UserType from storage:", type);
    //   console.log("Condition result:", type !== 'user');
    //   console.log("showCallback value:", this.showCallback);
    //     console.log(type, this.showCallback);

    //   }
    // })

    // if (navigator.geolocation) {
    //   if (this.ifMobileApp()) {
    //     navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError);
    //   } else {
    //     // navigator.geolocation.getCurrentPosition(position => {
    //     //   this.appService.userLocation.next({
    //     //     latitude: position.coords.latitude,
    //     //     longitude: position.coords.longitude
    //     //   })
    //     // });
    //   }
    // }
    if (this.ifMobileApp()) {
     

      // setTimeout(() => {
      //   (window as any).cordova.plugins.firebase.messaging.getToken().then(function (token) {
      //     localStorage.setItem('appToken', token);
      //   });
      //   (window as any).cordova.plugins.firebase.messaging.onMessage(function (payload) {
      //     console.log("New foreground FCM message: ", payload);
      //   });

      //   (window as any).cordova.plugins.firebase.messaging.requestPermission().then(() => {
      //     console.log('Permission granted');
      //   }).catch((err) => {
      //     console.log('Unable to get permission to notify.', err);
      //   });
      // }, 5000);
      // const router = this.router;
      // (window as any).cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
      //   if (payload.messagetype == "userReview") {
      //     router.navigate([payload.url], { queryParams: { scrollValueTo: "recentReviews" } });
      //   }
      //   if (payload.messagetype == "bookingCompleted") {
      //     router.navigate([payload.url], { queryParams: { bookingid: payload.queryvalue } });
      //   }
      // });

      setTimeout(() => {
        const router = this.router;
        (window as any).FirebasePlugin.getToken((token) => {
          console.log(`token: ${token}`);
          localStorage.setItem('appToken', token);
        });
        (window as any).FirebasePlugin.onMessageReceived((message) => {
          if (message.tap) {
            if (message.messagetype == "userReview") {
              router.navigate(['/merchant-bookings'], { queryParams: { scrollValueTo: "recentReviews" } });
            }
            if (message.messagetype == "bookingCompleted") {
              router.navigate(['/profile-management/bookings'], { queryParams: { bookingid: message.queryvalue } });
            }
          }
        });

        (window as any).FirebasePlugin.grantPermission(function (hasPermission) {
          console.log(
            "Notifications permission was " + (hasPermission ? "granted" : "denied")
          );
        });
      }, 4000);

    }

    if(this.ifMobileApp() && !this.ifIosApp()){
      document.addEventListener('deviceready', ()=>{
        var permissions = (window as any).cordova.plugins.permissions;
        permissions.checkPermission(permissions.ACTIVITY_RECOGNITION, function (status) {
          if (!status.hasPermission) {
            permissions.requestPermission(permissions.ACTIVITY_RECOGNITION, success, error);
            function error() {
              console.warn('Activity permission is not enabled');
            }
            function success(status) {
              locationBg.fetchbackgroundLocations();
              console.log('Permission granted');
              if (!status.hasPermission) error();
            }
          }
        });
      }, false);

      this.loadLocation();
      if (localStorage.getItem('pendingLocations') != null) {
        let pendingLocations = JSON.parse(localStorage.getItem('pendingLocations'));
        if (pendingLocations.length > 0) {
          for (let i = 0; i < pendingLocations.length; i++) {
            this.http.post(environment.apiUrl + '/merchantmaplocations/create', pendingLocations[i]).subscribe((res) => { })
          }
          localStorage.setItem('pendingLocations', null);
        }
      }

      setInterval(()=>{
          this.loadLocation();
      }, 30*1000)
    }

  }

  loadLocation() {
    if (localStorage.getItem('UserType') === 'user') {
      if (localStorage.getItem('packageTourBooking') == null || localStorage.getItem('packageTourBooking') == undefined) {
        this.authService.userInfo.subscribe(data => {
          let userid = data.id;
          this.http.post(environment.apiUrl + '/bookings/get-package-tour-bookings', { userId: userid, currentDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'), sort:'DESC' }).subscribe((res: any) => {
            if (res.status === "SUCCESS") {
              if (res.bookings.length > 0) {
                localStorage.setItem('packageTourBooking', JSON.stringify(res.bookings[0]));
                document.addEventListener('deviceready', this.onDeviceReady, false);
              } else {
                localStorage.setItem('packageTourBooking', null)
              }
            }
          })
        })
      } else {
        (window as any).BackgroundGeolocation.checkStatus(function(status) {
          if (!status.isRunning) {
            document.addEventListener('deviceready', locationBg.onDeviceReady(), false);
          }
        })
      }
    }
  }

  onDeviceReady() {
    if(!locationBg.ifIosApp()){
    var permissions = (window as any).cordova.plugins.permissions;
    permissions.checkPermission(permissions.ACTIVITY_RECOGNITION, function (status) {
      if (status.hasPermission) {
        locationBg.fetchbackgroundLocations();
      }
      else {
        permissions.requestPermission(permissions.ACTIVITY_RECOGNITION, success, error);
        function error() {
          console.warn('Activity permission is not enabled');
        }
        function success(status) {
          locationBg.fetchbackgroundLocations();
          console.log('Permission granted');
          if (!status.hasPermission) error();
        }
      }
    });
    }else{
      // locationBg.fetchbackgroundLocations();
    }
  }

  fetchbackgroundLocations() {
    console.log("fetchbackgroundLocations");
    (window as any).BackgroundGeolocation.configure({
      locationProvider: (window as any).BackgroundGeolocation.ACTIVITY_PROVIDER,
      desiredAccuracy: (window as any).BackgroundGeolocation.HIGH_ACCURACY,
      stationaryRadius: 50,
      distanceFilter: 50,
      notificationTitle: 'Advenzone Background tracking',
      notificationText: 'enabled',
      notificationIcon: 'ic_launcher',
      debug: false,
      interval: 5000,
      fastestInterval: 5000,
      activitiesInterval: 5000,
    });

    (window as any).BackgroundGeolocation.on('location', function (location) {
      console.log("location", location);
      // handle your locations here
      // to perform long running operation on iOS
      // you need to create background task
      (window as any).BackgroundGeolocation.startTask(function (taskKey) {
        // execute long running task
        // eg. ajax post location
        // IMPORTANT: task has to be ended by endTask
        console.log("taskKey", taskKey);
        if (localStorage.getItem('packageTourBooking') != null) {
          let booking: any = JSON.parse(localStorage.getItem('packageTourBooking'));
          console.log("booking", booking);
          const enddate = booking.enddate;
          const [year, month, day] = enddate.split('-').map(Number);
          const date = new Date(year, month - 1, day, 23, 59, 0); // Note: Months are zero-indexed (0 = January, 6 = July)
          const endtimestamp = date.getTime();
          let now_time = new Date().getTime();
          if (now_time > endtimestamp) {
            localStorage.setItem('packageTourBooking', null);
            (window as any).BackgroundGeolocation.stop();
            return;
          }
          console.log(location.longitude, location.latitude)
          if (locationBg.appService.isDistanceGreaterThanTenMeters([location.longitude, location.latitude], [locationBg.longitude, locationBg.latitude])) {
            locationBg.latitude = location.latitude;
            locationBg.longitude = location.longitude;
            let payload: any = {
              status: 1,
              createdBy: booking.userid,
              createdOn: now_time,
              activityId: booking.activityid,
              merchantId: booking.merchantid,
              locationType: "userlocation",
              latitude: location.latitude,
              longitude: location.longitude,
              bookingId: booking.id
            }
            const http = new XMLHttpRequest()
            http.open('POST', environment.apiUrl + '/merchantmaplocations/create', true)
            http.setRequestHeader('Content-type', 'application/json')
            http.send(JSON.stringify(payload)) // Make sure to stringify
            http.onload = function () {
              // Do whatever with response
              console.log(http.responseText)
            }
            http.onerror = function () {
              // Handle network errors
              console.error('Network Error');
              let pendingLocations: any = localStorage.getItem('pendingLocations');
              if (pendingLocations != null) {
                pendingLocations = JSON.parse(pendingLocations);
                pendingLocations.push(payload);
                localStorage.setItem('pendingLocations', JSON.stringify(pendingLocations));
              } else {
                let locations = [payload];
                localStorage.setItem('pendingLocations', JSON.stringify(locations));
              }
            };
          }
        } else {
          (window as any).BackgroundGeolocation.stop();
        }
        (window as any).BackgroundGeolocation.endTask(taskKey);
      });
    });

    (window as any).BackgroundGeolocation.on('error', function(error) {
      console.log('[ERROR] BackgroundGeolocation error:', error.code, error.message);
    });
  
    (window as any).BackgroundGeolocation.on('start', function() {
      console.log('[INFO] BackgroundGeolocation service has been started');
    });
  
    (window as any).BackgroundGeolocation.on('stop', function() {
      console.log('[INFO] BackgroundGeolocation service has been stopped');
    });
  
    (window as any).BackgroundGeolocation.on('authorization', function(status) {
      console.log('[INFO] BackgroundGeolocation authorization status: ' + status);
      if (status !== (window as any).BackgroundGeolocation.AUTHORIZED) {
        // we need to set delay or otherwise alert may not be shown
        setTimeout(function() {
          var showSettings = confirm('App requires location tracking permission. Would you like to open app settings?');
          if (showSettings) {
            return (window as any).BackgroundGeolocation.showAppSettings();
          }
        }, 1000);
      }
    });

    (window as any).BackgroundGeolocation.start();
  }

  isCallbackUrl(url: string): boolean {
    return url === '/blogs' || url === '/blogs-detail' || url === '/404-not-found' || url === '/contact-us';
  }
  // handleClickOutside(event: MouseEvent) {
  //   const target = event.target as HTMLElement;
  //   const container = this.elementRef.nativeElement.querySelector('.whatsappDisplay');
  //   const containerPhone = this.elementRef.nativeElement.querySelector('.phoneDisplay');
  //   if (!container.contains(target) && this.showLabel ) {
  //     this.showLabel = false;
  //   }
  //   if(!containerPhone.contains(target) && this.showCallLabel){
  //     this.showCallLabel= false;
  //   }
  // }

  ngAfterContentChecked() {
    if (this.ifIosApp() && !window.location.pathname.includes('/home') && !window.location.pathname.includes('/full-merchant-profile') && !window.location.pathname.includes('/partnerdashboard')) {
      var element = document.getElementById('ios-app');
      element.style.display = 'block';
    } else {
      var element = document.getElementById('ios-app');
      element.style.display = 'none';
    }
  }

  ifIosApp() {
    if ((window as any).cordova && (window as any).cordova.platformId === "ios") {
      return true;
    }
    return false;
  }

  onSuccess = (position: any) => {
    this.appService.userLocation.next({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    })
    console.log('Latitude: ' + position.coords.latitude + '\n' +
      'Longitude: ' + position.coords.longitude + '\n' +
      'Altitude: ' + position.coords.altitude + '\n' +
      'Accuracy: ' + position.coords.accuracy + '\n' +
      'Altitude Accuracy: ' + position.coords.altitudeAccuracy + '\n' +
      'Heading: ' + position.coords.heading + '\n' +
      'Speed: ' + position.coords.speed + '\n' +
      'Timestamp: ' + position.timestamp + '\n');
  };

  onError(error: any) {
    console.log('code: ' + error.code + '\n' +
      'message: ' + error.message + '\n');
  }

  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  openDialog() {
    this.dataService.data$.subscribe(data => {
      this.datafrom = data[0].datafrom
      this.name = data[0].name
      this.merchantId = data[0].merchantId
      this.activityType = data[0].activityType
    })

    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '87%',
      width: '33%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }
    this.dialog.open(EnquiryFormComponent, {
      ...sizes,
      disableClose: true,
      data: {
        datafrom: this.datafrom,
        name: this.name,
        activityType: this.activityType
      },
    });

  }
  ngOnDestroy() {
    // Remove event listener when component is destroyed to avoid memory leaks
    // document.body.removeEventListener('click', this.handleClickOutside.bind(this));
  }
  openWhatsapp() {
    const isAdvenzoneMobileApp = window.navigator.userAgent.includes('AdvenzoneMobileApp');

    if (isAdvenzoneMobileApp) {
      if ((window as any).cordova && (window as any).cordova.InAppBrowser) {
        (window as any).cordova.InAppBrowser.open('https://wa.me/7868899000', '_system', 'location=yes');
      }
    } else {
      window.location.href = "https://wa.me/7868899000"
    }
  }
  callSupport(){
    if (this.ifMobileApp()) {
      (window as any).cordova.InAppBrowser.open("tel:+917252927302", '_system', 'location=yes');
    } else {
      window.location.href="tel:+917252927302"
    }
  }

  toggleMenuVisibility(){
    this.showMenu = !this.showMenu;
  }


  // Listen for the window scroll event
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const yOffset = window.pageYOffset;
    this.isScrolled = yOffset > 400; // Show button if scrolled down 200px or more
  }

  scrollToTop(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

}
