import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityFilterService {

  private active_category_subject = new BehaviorSubject<any>(null);
  private category_index_count_subject = new BehaviorSubject<any>(null);
  private category_label_subject = new BehaviorSubject<any>(null);
  private search_rafting_subject = new BehaviorSubject<any>(null);
  private search_activity_subject = new BehaviorSubject<any>(null);
  private search_camping_subject = new BehaviorSubject<any>(null);
  private search_trekking_subject = new BehaviorSubject<any>(null);
  private search_tour_subject = new BehaviorSubject<any>(null);
  private search_merchants_subject = new BehaviorSubject<any>(null);
  private search_bikerental_subject = new BehaviorSubject<any>(null);
  private search_category_filter_subject = new BehaviorSubject<any>(null);

  public active_category_data$: Observable<any> = this.active_category_subject.asObservable();
  public category_index_count_data$: Observable<any> = this.category_index_count_subject.asObservable();
  public category_label_data$: Observable<any> = this.category_label_subject.asObservable();
  public search_rafting_data$: Observable<any> = this.search_rafting_subject.asObservable();
  public search_activity_data$: Observable<any> = this.search_activity_subject.asObservable();
  public search_camping_data$: Observable<any> = this.search_camping_subject.asObservable();
  public search_trekking_data$: Observable<any> = this.search_trekking_subject.asObservable();
  public search_tour_data$: Observable<any> = this.search_tour_subject.asObservable();
  public search_merchants_data$: Observable<any> = this.search_merchants_subject.asObservable();
  public search_bikerental_data$: Observable<any> = this.search_bikerental_subject.asObservable();
  public search_category_filter_data$: Observable<any> = this.search_category_filter_subject.asObservable();

  constructor() { }


  setActiveCategoryData(newData: any): void {
    this.active_category_subject.next(newData);
  }

  setCategoryIndexCountData(newData: any): void {
    this.category_index_count_subject.next(newData);
  }
  setCategoryLabelIndexCountData(newData: any): void {
    this.category_label_subject.next(newData);
  }

  setSearchRaftingData(newData: any): void {
    this.search_rafting_subject.next(newData);
  }

  
  setSearchActivityData(newData: any): void {
    this.search_activity_subject.next(newData);
  }

  setSearchCampingData(newData: any): void {
    this.search_camping_subject.next(newData);
  }

  setSearchTrekkingData(newData: any): void {
    this.search_trekking_subject.next(newData);
  }
  setSearchTourData(newData: any): void {
    this.search_tour_subject.next(newData);
  }
  setSearchTopAdventuristsData(newData: any): void {
    this.search_merchants_subject.next(newData);
  }

  setSearchCategoryFilterData(newData: any): void {
    this.search_category_filter_subject.next(newData);
  }

  setSearchBikeRentalData(newData: any): void {
    this.search_bikerental_subject.next(newData);
  }
}
