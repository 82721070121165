<section class=" fixed-margin-top">
  <div class="mx-md-5 mx-3">
    <div class="mobile-screen  py-3">
      <div class="wrapper landing-carousel-height mx-auto ">
        <img src="/assets/landing-page/new-cover-pic.jpg" alt="cover-img" class="image-src ">
        <h1 class="title position-absolute ">Advenzone</h1>
        <a href="javascript:void(0)" (click)="openLocation()"
          class="btn btn-success position-absolute landing-explore px-md-4 py-md-2 content">Explore
          <img src="/assets/explore-arrow.svg" width="20px" alt="icon explore"></a>
      </div>
    </div>

    <div class="row mt-md-5 mt-3">
      <div class="col-md-6 offset-md-1">
        <h1 class="name mb-2">Who are We</h1>
        <p class="content text-justify">
          Your Gateway to Unstoppable Adventure. Embark on a journey of a lifetime with Advenzone, your ultimate companion for thrilling experiences across India. We're a tribe of passionate adventurers turned trip curators, dedicated to connecting you with the most epic, heart-racing, and soul-stirring experiences our incredible country has to offer.
        </p>
        <p class="content text-justify">
          At Advenzone, we believe that everyone has an inner adventurer waiting to be unleashed. Our mission is to ignite that spark within you, whether you're a seasoned thrill-seeker or just starting to explore the world of adventure. We're committed to providing you with unforgettable experiences that push your limits, challenge your fears, and leave you yearning for more.
        </p>
      </div>
      <div class="col-md-5 p-2 justify-content-md-center d-md-flex">
        <img src="/assets/landing-page/rafting.jpg" alt="rafting" class="image-size">
      </div>
    </div>

    <div class="row mt-md-5 mt-3 choose-content">
      <div class="col-md-6  mt-md-0 mt-3">
        <div class="d-flex justify-content-center">
          <div class="d-flex align-items-start flex-column me-md-4 me-3">
            <img src="/assets/landing-page/bike-ride-mountain.jpg" alt="image" class="image">
            <img src="/assets/landing-page/group-camp.jpg" alt="group-camp" class="image my-3">
          </div>
          <div class="d-flex align-items-start flex-column mt-4">
            <img src="/assets/landing-page/mountain-climbing-girl.jpg" alt="mountain-climbing" class="image">
            <img src="/assets/landing-page/air-balloon.jpg" alt="hot-air-balloon" class="image my-3">
          </div>
        </div>
      </div>
      <div class="col-md-5 ">
        <h1 class="name mb-2">Why Choose us</h1>
        <p class="content text-justify">
          To cultural immersions, wildlife encounters, and offbeat explorations, our carefully curated selection ensures that there's something for every kind of adventurer.
        </p>
        <ul class="content">
          <li>Unparalleled expertise: Our team knows India's adventure scene inside out. </li>
          <li>Quality assurance: We vet every experience to ensure safety and excellence.</li>
          <li>Personalized service: Your adventure, tailored to your preferences.</li>
          <li>Unbeatable value: Competitive pricing without compromising on quality.</li>
        </ul>
        <p class="content text-justify">
          Our team of seasoned adventurers and travel experts handpick the best activities and experiences in every corner of India. From:
        </p>
        <ul class="content">
          <li>Skydiving over majestic landscapes</li>
          <li>Scuba diving in crystal-clear waters</li>
          <li>Trekking through breathtaking Himalayan trails</li>
          <li>Paragliding over scenic valleys</li>
          <li>Rock climbing sheer mountain faces</li>
          <li>Zip lining through lush forests</li>
        </ul>
        <div class="explore-div">
          <a href="javascript:void(0)" class="btn btn-success explore px-4 py-2 my-2" (click)="openLocation()">Explore
            <img src="/assets/explore-arrow.svg" width="20px" alt="explore"></a>
        </div>
      </div>
      <div class="offset-md-1"></div>
    </div>

    <div class="mt-4">
      <h1 class="name d-flex justify-content-center">Easy Bookings</h1>
      <div class="row  px-md-4  px-2 activity-background mb-3 mx-auto ">
        <div class="col-md-4 mt-3 mt-md-0 text-center position-relative px-md-2 px-0">
          <div class="landing-activity">
            <img src="/assets/landing-rafting.svg" alt="landing-rafting" class="landing-icon">
          </div>
          <h3 class="activity-title mb-md-2 mb-1 ">Choose Activity</h3>
          <p class="px-md-4 px-5 activity-content">Fun from the get-go is what we like and that's exactly what we
            deliver.</p>
        </div>
        <div class="col-md-4 mt-3 mt-md-0 text-center position-relative px-md-2 px-0">
          <div class="landing-activity">
            <img src="/assets/landing-merchant.svg" alt="landing-merchant" class="landing-icon">
          </div>
          <h3 class="activity-title mb-md-2 mb-1">Choose Merchant</h3>
          <p class="px-md-4 px-5 activity-content">Fun from the get-go is what we like and that's exactly what we
            deliver.</p>
        </div>
        <div class="col-md-4 mt-3 mt-md-0 text-center position-relative px-md-2 px-0">
          <div class="landing-activity">
            <img src="/assets/landing-booking.svg" alt="landing-booking" class="landing-icon">
          </div>
          <h3 class="activity-title mb-md-2 mb-1">Book</h3>
          <p class="px-md-4 px-5  activity-content">Fun from the get-go is what we like and that's exactly what we
            deliver.</p>
        </div>
      </div>
    </div>
  </div>

    <div class="mt-md-5 mt-3">
      <h1 class="name d-flex justify-content-center ">Testimonials</h1>
      <div id="popular-spots-lists" class="landing-page-testimonials">
        <div class="parent" #elemt (mousedown)="startDragging($event, false, elemt,'mouse',child, '')"
          (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
          (mousemove)="moveEvent($event, elemt, 'mouse', child)" (scroll)="onScroll($event,elemt, child)">
          <div class="child d-flex" #child>
            <div class="card landing-content ms-3 border-0" *ngFor="let review of  reviews,let i= index">
              <div class="card-body p-2">
                <div class="container px-2">
                  <p class="review-content">{{review.review}}</p>
                  <div class="landing-profile d-flex">
                    <img src="/assets/default-profile-pic.svg" *ngIf="!review.src" alt="landing-rafting"
                      class="rounded-circle me-2">
                    <img [src]="review.src" *ngIf="review.src" alt="landing-rafting" class="rounded-circle me-2">
                    <div class="mt-2 d-flex">
                      <ng-template #t let-fill="fill">
                        <span [style.color]="fill === 100 ? 'orange' : 'gray'">&#9733;</span>
                      </ng-template>
                      <ngb-rating [max]="5" [starTemplate]="t" [readonly]="true" [(rate)]="review.star"></ngb-rating>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-md-5 mt-3 mx-3">
      <div class="col-md-10 mx-auto px-md-3">
        <h1 class="name mb-1">Frequently Asked Questions (FAQs)</h1>
        <section class="mt-3">
          <div class="content  mx-2 ">
            <div class="d-flex pointer"  (click)="toggleContent(0)">
              <div class="col-10">
                <h3 class="content-question">How does the booking process work?</h3>
              </div>
              <div class="col-2 mt-2 pointer  text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  *ngIf="visibilityStates[0]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                   *ngIf="!visibilityStates[0]">
              </div>
            </div>
            <ul *ngIf="visibilityStates[0]">
              <li>Browse our website and select your desired adventure activity.</li>
              <li>Review the available options, including dates, times, and prices.
              </li>
              <li>Click on the "Book Now" button and follow the prompts to complete your booking.
              </li>
              <li>Make the required payment to secure your reservation.</li>
              <li>Once your booking is confirmed, you'll receive a confirmation email with all the details. </li>
            </ul>

            <div class="d-flex pointer" (click)="toggleContent(1)" >
              <div class="col-10">
                <h3 class="content-question">What happens if I need to cancel or change my booking?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                *ngIf="visibilityStates[1]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                 *ngIf="!visibilityStates[1]">
              </div>
            </div>
            <p *ngIf="visibilityStates[1]" class="text-justify">Cancellation and change policies vary depending on the
              specific adventure
              activity and the vendor. Please refer to the cancellation policy provided during the booking process or in
              the Terms and Conditions on our website. If you need assistance, reach out to our customer support team,
              and we'll guide you through the process.</p>

            <div class="d-flex pointer" (click)="toggleContent(2)" >
              <div class="col-10">
                <h3 class="content-question">How do I pay for my adventure activity?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                  *ngIf="visibilityStates[2]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                   *ngIf="!visibilityStates[2]">
              </div>
            </div>
            <p *ngIf="visibilityStates[2]" class="text-justify">When booking through our website, you'll be prompted to
              make a payment for a
              percentage of the total cost to secure your booking. The remaining amount is usually paid directly to the
              vendor either before or after the activity, as specified by the vendor. Payment methods and details will
              be provided during the booking process.</p>

            <div class="d-flex pointer"  (click)="toggleContent(3)" >
              <div class="col-10">
                <h3 class="content-question">Are the adventure activities safe?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                 *ngIf="visibilityStates[3]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                *ngIf="!visibilityStates[3]">
              </div>
            </div>
            <p *ngIf="visibilityStates[3]" class="text-justify">We prioritize your safety and work with trusted vendors
              who maintain high
              safety standards. Our partner vendors are experienced professionals who follow strict safety protocols and
              regulations. However, adventure activities inherently carry some risks. It's important to carefully read
              and follow the safety guidelines provided by the vendor and ensure that you meet any specified
              requirements or restrictions.</p>

            <div class="d-flex pointer"   (click)="toggleContent(4)">
              <div class="col-10">
                <h3 class="content-question">What should I bring or wear for the adventure activities?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                 *ngIf="visibilityStates[4]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                 *ngIf="!visibilityStates[4]">
              </div>
            </div>
            <p *ngIf="visibilityStates[4]" class="text-justify">Each adventure activity may have specific
              recommendations or requirements.
              It's best to refer to the activity description or contact the vendor directly for detailed information. In
              general, it's advisable to wear comfortable and appropriate clothing, closed-toe shoes, and bring any
              necessary equipment specified by the vendor. Don't forget essentials like sunscreen, water, and a camera
              to capture your unforgettable moments!
            </p>

            <div class="d-flex pointer"  (click)="toggleContent(5)" >
              <div class="col-10">
                <h3 class="content-question" class="content-question">Can I book activities for a group or special
                  events?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                 *ngIf="visibilityStates[5]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                 *ngIf="!visibilityStates[5]">
              </div>
            </div>
            <p *ngIf="visibilityStates[5]" class="text-justify">Absolutely! Our platform allows you to book activities
              for groups, special
              occasions, team-building events, or other gatherings. During the booking process, you'll have the
              opportunity to indicate the number of participants or inquire about group packages. If you require any
              additional assistance or have specific requests, please reach out to our customer support team, and we'll
              be happy to assist you.
            </p>

            <div class="d-flex pointer"  (click)="toggleContent(6)" >
              <div class="col-10">
                <h3 class="content-question">How can I contact customer support?</h3>
              </div>
              <div class="col-2 mt-2 pointer text-end">
                <img src="../../assets/arrow-up1.svg" class="ms-4 mb-3 pointer arrow" alt="arrow"
                 *ngIf="visibilityStates[6]">
                <img src="../../assets/down-arrow.svg" class="ms-4 mb-3 pointer arrow" alt="downArrow"
                 *ngIf="!visibilityStates[6]">
              </div>
            </div>
            <p *ngIf="visibilityStates[6]" class="text-justify">If you have any questions, concerns, or need assistance,
              our dedicated
              customer support team is here to help. You can reach us through the contact information provided on our
              website or by using the messaging system available on your user account. We strive to provide prompt and
              helpful responses to ensure your adventure experience is smooth and enjoyable.</p>
          </div>
        </section>
      </div>
    </div>

    <div class="mt-md-5 mt-3 mx-3">
      <h1 class="name d-flex justify-content-center">Follow us on Social Media</h1>
      <div class="d-flex  justify-content-center">
        <a href="https://www.facebook.com/AdvenzoneApp" target="_blank">
          <img src="/assets/fb.svg" width="52px" alt="fb" class="mx-3 my-2 shadow icon"></a>
        <a href="https://www.instagram.com/advenzone/" target="_blank">
          <img src="/assets/instagram.svg" width="52px" alt="instagram" class="mx-3 my-2 shadow icon"></a>
        <a href="https://www.linkedin.com/company/advenzone/" target="_blank">
          <img src="/assets/linkedIn.svg" width="52px" alt="linkedIn" class="mx-3 my-2 shadow icon"></a>
      </div>
    </div>

  <div class="row mt-md-5 mt-3 mx-md-5 mx-2 my-3">
    <div class="col-md-6 px-md-0 px-3">
      <div class="row">
        <div class="col-8 pe-1">
          <div class="landing-page-gallery-img height-400" style="background-image:  url(/assets/landing-page/rafting.jpg); height: 400px;"></div>
        </div>
        <div class="col-4 ps-1">
          <div class="landing-page-gallery-img height-195" style="background-image:  url(/assets/landing-page/mountain-climbing.jpg); height: 195px; margin-bottom: 5px;"></div>
          <div class="landing-page-gallery-img  height-200" style="background-image:  url(/assets/Baga-Beach.png); height: 200px;"></div>
        </div>
        <div class="col-4 pe-1 mt-2">
          <div class="landing-page-gallery-img  height-200" style="background-image:  url(/assets/landing-page/banana-ride.jpg); height: 200px;"></div>
        </div>
        <div class="col-8 ps-1 mt-2">
          <div class="landing-page-gallery-img  height-200" style="background-image:  url(/assets/landing-page/mountain-view.jpg); height: 200px;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-6 my-md-3 px-md-0 px-3 mt-md-0 mt-2">
      <div class="row">
        <div class="col-8 pe-1">
          <div class="landing-page-gallery-img height-400 landing-page-gallery-img-custom" style="background-image:  url(/assets/landing-page/group-camp.jpg); height: 400px;"></div>
        </div>
        <div class="col-4 ps-1">
          <div class="landing-page-gallery-img  height-195" style="background-image:  url(/assets/landing-page/mountain-climbing-girl.jpg); height: 195px; margin-bottom: 5px;"></div>
          <div class="landing-page-gallery-img height-200" style="background-image:  url(/assets/landing-page/bungy-jumping.jpg);height: 200px;"></div>
        </div>
        <div class="col-4 pe-1 mt-2">
          <div class="landing-page-gallery-img  height-200 landing-page-gallery-img-custom" style="background-image:  url(/assets/landing-page/water-game.jpg); height: 200px;"></div>
        </div>
        <div class="col-8 ps-1 mt-2">
          <div class="landing-page-gallery-img  height-200" style="background-image:  url(/assets/landing-page/motorcycle.jpg); height: 200px;"></div>
        </div>
      </div>
    </div>
    </div>

</section>
<app-footer></app-footer>
