import { Component } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsFormComponent } from '../contact-us-form/contact-us-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerDashboardComponent } from '../partner/partner-dashboard/partner-dashboard.component';
import { PartnerLoginDialogueComponent } from '../auth/partner-login-dialogue/partner-login-dialogue.component';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MerchantLoginDialogueComponent } from '../auth/merchant-login-dialogue/merchant-login-dialogue.component';
import { GuestLoginDialogueComponent } from '../auth/guest-login-dialogue/guest-login-dialogue.component';
import { LoginDialogComponent } from '../auth/login-dialog/login-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  UserType: any = null;
  userAgent: string = "";
  bookingSource: string;
  hostName: any = "";
  isSubmitting: boolean = false;
  contactUsForm!: FormGroup;
  fromSupportCollapsed:boolean = true;
  fromQuickLinksCollapsed:boolean = true;

  constructor(private auth: AuthService, public dialog: MatDialog, public router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private http: HttpClient) {
    this.bookingSource = localStorage.getItem('bookingType') || '';
    this.hostName = window.location.host;
    // this.bookingSource = localStorage.getItem('bookingType') == null ? "" : localStorage.getItem('bookingType');
    this.auth.userInfo.subscribe(info => {
      if (info !== null) {
        this.auth.UserType.subscribe(type => {
          this.UserType = type;
        })
      }
    })
  }

  ngOnInit() {
    this.userAgent = window.navigator.userAgent;
    this.contactUsForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, this.validatePhoneNumber]),
      name: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required)
    });
  }

  ifMobileApp() {
    if (this.userAgent.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  getYear(){
    return new Date().getFullYear();
  }

  openContactUsForm() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '50%',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      }
    }
    this.dialog.open(ContactUsFormComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  clear_localStorage() {
    var appToken = localStorage.getItem('appToken');
    localStorage.clear();
    window.location.href = '/index.html';
    localStorage.setItem('appToken', appToken);
  }

  loginPartner() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '400px',
      width: '35%',
      maxWidth: 'unset',
      panelClass: 'bg-partner-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-partner-class'
    }
    }
    this.dialog.open(PartnerLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }


  openUrl(url: any) {
    window.open(url)
  }

  navigateHome(){
    sessionStorage.clear();
    let location = JSON.parse(localStorage.getItem('location'));
    let locationId = location.id;
    let locationname = location.locationname.trim().toLowerCase().replaceAll(' ', '-');
    this.router.navigate(['/location', locationId, locationname]);
  }

  validatePhoneNumber(control: any) {
    const phoneNumber = control.value;
    const phoneNumberPattern = /^([6-9]\d{9})$/; // Regular expression for 10-digit number

    if (phoneNumber && !phoneNumber.match(phoneNumberPattern)) {
      return { invalidPhoneNumber: true };
    }

    return null;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactUsForm.controls;
  }

  // onFormSubmit() {
  //   this.isSubmitting = true;
  //   if (this.contactUsForm.invalid) {
  //     return;
  //   }
  //   const isMobile = window.innerWidth < 768;
  //   let sizes = {
  //     height: 'fit-content',
  //     width: 'fit-content',
  //     maxWidth: '50vw',
  //   };
  //   if (isMobile) {
  //     sizes = {
  //       height: 'fit-content',
  //       width: 'fit-content',
  //       maxWidth: 'unset',
  //     }
  //   }
  //   this.http.post(`${environment.apiUrl}/users/contactus`, { ...this.contactUsForm.value }).pipe(
  //     catchError((_err: HttpErrorResponse) => {
  //       this.dialog.open(ModalAlertComponent, {
  //         ...sizes,
  //         disableClose: true,
  //         data: {
  //           title: 'Some Error occurs',
  //           message: JSON.stringify(_err.error.errors),
  //           status: 'danger'
  //         },
  //       })
  //       throw new Error("Error");
  //     })
  //   ).subscribe((data: any) => {
  //     if (data.status === "SUCCESS") {
  //       this.contactUsForm.reset();
  //       this.dialog.open(ModalAlertComponent, {
  //         ...sizes,
  //         disableClose: true,
  //         data: {
  //           title: 'Thank you',
  //           message: 'The Admin will contact you within 24 to 48 hours.',
  //           status: 'success'
  //         },
  //       })
  //     }
  //   });
  // }

  openProfile() {
    if (!this.ifMobileApp()) {
      const queryString = window.location.search;
      const searchParams = new URLSearchParams(queryString);
      const queryParams:any = Object.fromEntries(searchParams.entries());
      const currentUrl = this.router.url.split('?')[0];
      let q = Object.keys(queryParams).map(k => k !== 'dialogue' ? `${k}=${queryParams[k]}` : '').filter(k => k !== '').join('&');
      q = q !== '' ? q + '&dialogue=user' : q + `dialogue=user`
      window.location.href = `${currentUrl}?${q}`;
    } else {
      const isMobile = window.innerWidth < 768;
      let sizes = {
        height: '90vh',
        width: '40%',
        maxWidth: 'unset',
        panelClass: 'bg-login-class'
      };
      if (isMobile) {
        sizes = {
          width: '100%',
          maxWidth: 'unset',
          height: '100%',
          panelClass: 'bg-login-class'
        }
      }
      this.dialog.open(LoginDialogComponent, {
        ...sizes,
        disableClose: true,
        data: {

        },
      });
    }
  }

  guestLogin() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '40%',
      maxWidth: 'unset',
      panelClass: 'bg-guest-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-guest-login-class'
      }
    }
    this.dialog.open(GuestLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }


  openMerchantLoginDialogue() {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      height: '500px',
      width: '40%',
      maxWidth: 'unset',
      panelClass: 'bg-merchant-login-class'
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
        panelClass: 'bg-merchant-login-class'
      }
    }
    this.dialog.open(MerchantLoginDialogueComponent, {
      ...sizes,
      disableClose: true,
      data: {

      },
    });
  }

  collapseFooter(from:any){
    if(from == 'support'){
      this.fromSupportCollapsed = !this.fromSupportCollapsed;
    }
    if(from == 'quicklinks'){
      this.fromQuickLinksCollapsed = !this.fromQuickLinksCollapsed;
    }
  }
}
