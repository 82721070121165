import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivityFilterService } from 'src/app/services/activity-filter.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { FormatDateRangeService } from 'src/app/services/format-date-range.service';

declare var $: any;

@Component({
  selector: 'app-mobile-filter-form',
  templateUrl: './mobile-filter-form.component.html',
  styleUrls: ['./mobile-filter-form.component.css']
})
export class MobileFilterFormComponent {

  activityFilterSubscription: any
  activityFilterIndexSubscription: any;
  activityFilterCategoryLabelSubscription:any;

  active_category: any;
  active_category_type:any;
  isSubmittingRafting: boolean = false;
  isSubmittingActivities: boolean = false;
  isSubmittingCamping: boolean = false;
  isSubmittingTrekking:boolean = false;
  isSubmittingBikeTour:boolean = false;
  isSubmittingTopAdventurists:boolean = false;
  isSubmittingBikerental:boolean = false;
  newTimings: any = [];
  timings: any = ["12:00 am", "1:00 am", "2:00 am", "3:00 am", "4:00 am", "5:00 am", "6:00 am", "7:00 am", "8:00 am", "9:00 am", "10:00 am", "11:00 am", "12:00 pm", "1:00 pm", "2:00 pm", "3:00 pm", "4:00 pm", "5:00 pm", "6:00 pm", "7:00 pm", "8:00 pm", "9:00 pm", "10:00 pm", "11:00 pm"];
  raftingForm!: FormGroup;
  activitiesForm!: FormGroup;
  campingForm!: FormGroup;
  trekkingForm!: FormGroup;
  biketourForm!: FormGroup;
  rentalForm!: FormGroup;
  topAdventuristsForm!: FormGroup;
  currentDate: string | undefined;
  currentDate1: string | undefined;
  dropDate: string | undefined;
  locationId: any = null;
  raftingAdultsCount: number = 1;
  rafting_filter_visible: boolean = false;
  activity_filter_visible: boolean = false;
  camping_filter_visible: boolean = false;
  biketour_filter_visible: boolean = false;
  trekking_filter_visible: boolean = false;
  topadventurists_filter_visible: boolean = false;
  bikerentals_filter_visible: boolean = false;
  indexData: any;
  quickActivityTypes: any = [];
  startDate: string = '';
  allSlots: any = [];
  slots: any = [];
  months: any = [];
  loadingMonths:boolean = false;
  category_labels:any =[];

  selectedRaftingMinPrice: number = 0;
  selectedRaftingMaxPrice: number = 0;
  selectedQuickActivitiesMinPrice: number = 0;
  selectedQuickActivitiesMaxPrice: number = 0;
  selectedCampingMinPrice: number = 0;
  selectedCampingMaxPrice: number = 0;
  selectedBikeTourMinPrice: number = 0;
  selectedBikeTourMaxPrice: number = 0;
  selectedTrekkingMinPrice: number = 0;
  selectedTrekkingMaxPrice: number = 0;
  selectedBikeRentalsMinPrice: number = 0;
  selectedBikeRentalsMaxPrice: number = 0;
  selectedCategoryMinPrice: number = 0;
  selectedCategoryMaxPrice: number = 0;

  adultsCount: number = 1;
  childrenCount: number = 0;
  totalGuest: number = 1;

  variationid: any = [];
  variationValueId: any = [];
  variations: any = [];
  variationValues: any = [];
  inventoryVariations: any = [];
  inventoryVariationValues: any = [];
  inventoryVariationValueIds: any = [];

  constructor(private bottomSheetRef: MatBottomSheetRef<MobileFilterFormComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private http: HttpClient, private router: Router, public auth: AuthService, private activityFilterService: ActivityFilterService, private formBuilder: FormBuilder, private datePipe: DatePipe, private formatDateRangeService: FormatDateRangeService) {
    let location = localStorage.getItem('location');
    if (location != null && location != undefined && location != "") {
      this.locationId = JSON.parse(location).id;
    }
    this.activityFilterCategoryLabelSubscription = this.activityFilterService.category_label_data$.subscribe((data:any) => {
      if(data && data != null){
        this.category_labels = data.category_labels;
      }else{
        this.category_labels = [];
      }
    })

    this.activityFilterSubscription = this.activityFilterService.active_category_data$.subscribe((data: any) => {
      if (data && data.active_category != null) {
        this.active_category = data.active_category;
        this.active_category_type = '';
        let selected_category = this.category_labels.find((x: any) => x.id == this.active_category);
        if (selected_category && selected_category.hasUniqueCategory == true) {
          this.showPhase(selected_category.uniqueCategory);
          this.active_category_type = selected_category.uniqueCategory;
        } else {
          this.showPhase(this.active_category);
          this.active_category_type = '';
        }
      }
    })

    this.activityFilterIndexSubscription = this.activityFilterService.category_index_count_data$.subscribe((data: any) => {
      this.indexData = data;
      if(this.indexData && this.indexData.rafting_min_price){
        this.selectedRaftingMinPrice = this.indexData.rafting_min_price;
        this.selectedRaftingMaxPrice = this.indexData.rafting_max_price;
        this.selectedQuickActivitiesMinPrice = this.indexData.quickactivities_min_price;
        this.selectedQuickActivitiesMaxPrice = this.indexData.quickactivities_max_price;
        this.selectedCampingMinPrice = this.indexData.camping_min_price;
        this.selectedCampingMaxPrice = this.indexData.camping_max_price;
        this.selectedBikeTourMinPrice = this.indexData.tour_min_price;
        this.selectedBikeTourMaxPrice = this.indexData.tour_max_price;
        this.selectedTrekkingMinPrice = this.indexData.trekking_min_price;
        this.selectedTrekkingMaxPrice = this.indexData.trekking_max_price;
        this.selectedBikeRentalsMinPrice = this.indexData.bikerentals_min_price;
        this.selectedBikeRentalsMaxPrice = this.indexData.bikerentals_max_price;
      }
    })

  }


  getRangePrice(type: string) {
    if (this.category_labels.length > 0) {
      if (type == 'min') {
        return this.category_labels.find((x: any) => x.id == this.active_category).min_price;
      } else {
        return this.category_labels.find((x: any) => x.id == this.active_category).max_price;
      }
    } else {
      switch (this.active_category) {
        case 'rafting':
          if (type == 'min') {
            return this.indexData.rafting_min_price;
          } else {
            return this.indexData.rafting_max_price;
          }
          break;
        case 'bikerental':
          if (type == 'min') {
            return this.indexData.bikerentals_min_price;
          } else {
            return this.indexData.bikerentals_max_price;
          }
          break;
        case 'trekking':
          if (type == 'min') {
            return this.indexData.trekking_min_price;
          } else {
            return this.indexData.trekking_max_price;
          }
          break;
        case 'tour':
          if (type == 'min') {
            return this.indexData.tour_min_price;
          } else {
            return this.indexData.tour_max_price;
          }
          break;
        case 'activity':
          if (type == 'min') {
            return this.indexData.quickactivities_min_price;
          } else {
            return this.indexData.quickactivities_max_price;
          }
          break;
        case 'camping':
          if (type == 'min') {
            return this.indexData.camping_min_price;
          } else {
            return this.indexData.camping_max_price;
          }
          break;
      }
    }
  }

  ngOnInit() {
    this.currentDate = this.getCurrentDate();
    this.startDate = this.getCurrentDate();
    this.dropDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
  }

  ngOnDestroy() {
    if (this.activityFilterSubscription) {
      this.activityFilterSubscription.unsubscribe();
    }
    if (this.activityFilterIndexSubscription) {
      this.activityFilterIndexSubscription.unsubscribe();
    }
    if(this.activityFilterCategoryLabelSubscription){
      this.activityFilterCategoryLabelSubscription.unsubscribe();
    }
  }

  showPhase(event: any) {
    switch (event) {
      case "rafting":
        this.FormRafting();
        if (this.category_labels.length > 0) {
          this.selectedRaftingMinPrice = this.getRangePrice('min');
          this.selectedRaftingMaxPrice = this.getRangePrice('max');
        }
        break;
      case "activity":
        this.FormActivities();
        if (this.category_labels.length > 0) {
          this.selectedQuickActivitiesMinPrice = this.getRangePrice('min');
          this.selectedQuickActivitiesMaxPrice = this.getRangePrice('max');
        }
        break;
      case "camping":
        this.FormCamping();
        if (this.category_labels.length > 0) {
          this.selectedCampingMinPrice = this.getRangePrice('min');
          this.selectedCampingMaxPrice = this.getRangePrice('max');
        }
        break;
      case "trekking":
        this.FormTrekking();
        if (this.category_labels.length > 0) {
          this.selectedTrekkingMinPrice = this.getRangePrice('min');
          this.selectedTrekkingMaxPrice = this.getRangePrice('max');
        }
        break;
      case "tour":
        this.FormTour();
        if (this.category_labels.length > 0) {
          this.selectedBikeTourMinPrice = this.getRangePrice('min');
          this.selectedBikeTourMaxPrice = this.getRangePrice('max');
        }
        break;
      case "topAdventurists":
        this.FormTopAdventurists();
        break;
      default:
        this.selectedCategoryMinPrice = this.getRangePrice('min');
        this.selectedCategoryMaxPrice = this.getRangePrice('max');
        break;
    }
  }

  FormRafting() {
    this.isSubmittingRafting = false;
    this.newTimings = [];
    this.newTimings.push(...this.timings);
    this.raftingForm = this.formBuilder.group({
      activityId: new FormControl(''),
      date: new FormControl(''),
      time: new FormControl(''),
      distance: new FormControl(''),
      guest: new FormControl('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]),
    });
    this.currentDate = this.getCurrentDate();
    this.raftingForm.get('date').setValue(this.currentDate);
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=rafting&date=${this.alignDate(this.currentDate)}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if (Number(data.min_price) == 0) {
            this.currentDate = "";
            this.raftingForm.get('date').setValue(this.currentDate);
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  onChangeRaftingValues() {
    this.raftingForm.get('time')?.setValue('');
    let activityData = [];
    let filter: any = {};
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/activities/raftingdistance`, { ...filter, category: 'rafting', distance: this.raftingForm.value.distance, status: 1 }, { responseType: 'json' }).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          data.activities.forEach((activity: any) => {
            if (activity.additionalinfo != null && activity.additionalinfo != "") {
              activity.raftingkm = JSON.parse(activity.additionalinfo).raftingkm;
              if (activity.raftingkm === this.raftingForm.value.distance) {
                activityData.push(activity);
              }
            }
          });
          if (activityData.length > 0) {
            let filter: any = { activityId: activityData[0].id, variations: [] };
            if (this.locationId != null) {
              filter.locationId = this.locationId;
            }
            let start_time = [], end_time = [];
            this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, filter, { responseType: 'json' }).subscribe(async (data: any) => {
              {
                if (data.status === "SUCCESS") {
                  data.merchantactivities.forEach((x: any, i: any) => {
                    start_time.push(this.timings.indexOf(x.timefrom));
                    end_time.push(this.timings.indexOf(x.timeto));
                  })
                  let minNumber = Math.min(...start_time);
                  let maxNumber = Math.max(...end_time);
                  this.newTimings = [];
                  for (let i = minNumber; i <= maxNumber; i++) {
                    this.newTimings.push(this.timings[i]);
                  }
                }
              }
            })
          }
        }
      }
    })
  }

  FormActivities() {
    this.isSubmittingActivities = false;
    this.newTimings = [];
    this.newTimings.push(...this.timings);
    this.activitiesForm = this.formBuilder.group({
      activityId: new FormControl(''),
      activities: new FormControl(''),
      date: new FormControl('',),
      time: new FormControl(''),
    });
    this.currentDate = this.getCurrentDate();
    this.activitiesForm.get('date').setValue(this.currentDate);
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=activity&date=${this.alignDate(this.currentDate)}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if(Number(data.min_price) == 0){
            this.currentDate = "";
            this.activitiesForm.get('date').setValue(this.currentDate);
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
   
    this.http.get(environment.apiUrl + `/activities/quickactivitytypes?category=activity${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        this.quickActivityTypes = data.activity;
      }
    })
  }

  FormCamping(){
    this.isSubmittingCamping = false;
    this.campingForm = this.formBuilder.group({
      activityId: new FormControl(''),
      date: new FormControl(''),
      adult: new FormControl(''),
      children: new FormControl(''),
      guest: new FormControl(''),
      checkoutdate: new FormControl('1'),
      variations: this.formBuilder.array([])
    });
    this.currentDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${this.alignDate(this.currentDate)}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if(Number(data.min_price) == 0){
            this.currentDate = "";
            this.currentDate1 = "";
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
    if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
      let userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.value && userData.category === 'camping') {
        this.adultsCount = userData.value.adult;
        this.childrenCount = userData.value.children;
        this.totalGuest = userData.value.guest;
        this.currentDate1 = userData.value.checkoutdate;
        this.currentDate = userData.value.date;
        this.campingForm.setValue({
          activityId: userData.value.activityId,
          date: userData.value.date,
          adult: userData.value.adult,
          children: userData.value.children,
          guest: userData.value.guest,
          checkoutdate: userData.value.checkoutdate,
          variations: []
        });
      } else {
        sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
      }

    } else {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
    }
    this.variationid = [];
    this.variationValueId = [];
 
    this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=camping${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.activityVariationDetails.forEach((x: any) => {
          x.merchants.forEach((y: any) => {
            if (!this.variationid.includes(y.variationtypeid)) {
              this.variationid.push(y.variationtypeid);
            }
            let index = this.variationid.indexOf(y.variationtypeid);
            if (index != -1) {
              if (this.variationValueId[index] != undefined) {
                (!this.variationValueId[index].includes(y.variationvalueid)) ? this.variationValueId[index].push(y.variationvalueid) : ""
              } else {
                this.variationValueId[index] = [];
                this.variationValueId[index].push(y.variationvalueid)
              }
            }
          })
        })
        if (this.variationid.length > 0) {
          const variationsPromises = this.variationid.map(async (x: any) => {
            try {
              const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise();
              return data;
            } catch (error) {
              throw error;
            }
          });

          const variationValuePromises = this.variationValueId.map(async (x: any) => {
            const variationValuePromisesInner = x.map(async (y: any) => {
              try {
                const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
                return data;
              } catch (error) {
                throw error;
              }
            });
            return await Promise.all(variationValuePromisesInner);
          });

          try {
            this.variations = await Promise.all(variationsPromises);
            this.variationValues = await Promise.all(variationValuePromises);
            const variationArray = this.campingForm.get('variations') as FormArray;
            this.variations.forEach((x: any) => {
              variationArray.push(
                this.formBuilder.group({
                  variationid: new FormControl(x.variations.id),
                  type: new FormControl(x.variations.type),
                  variationvalueid: new FormControl(''),
                }))
            })
            if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
              let userData = JSON.parse(sessionStorage.getItem('userData'));
              if (userData.value && userData.category === 'camping') {
                const variationArray = this.campingForm.get('variations') as FormArray;
                if (userData.value.variations) {
                  userData.value.variations.forEach((x: any) => {
                    let indexToUpdate = variationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                    const variationGroupToUpdate = variationArray.at(indexToUpdate) as FormGroup;
                    variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
                  })
                }
                if (userData.isSubmittingCamping) {
                  // this.searchCamping();
                }
              } else {
                sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
              }
            } else {
              sessionStorage.setItem('userData', JSON.stringify({ category: 'camping', value: this.campingForm.value }))
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        } else {
          if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
            let userData = JSON.parse(sessionStorage.getItem('userData'));
            if (userData.category === 'camping') {
              if (userData.isSubmittingCamping) {
                // this.searchCamping();
              }
            }
          }
        }
      }
    })
  }

  
  FormTrekking() {
    this.isSubmittingTrekking = false;
    this.trekkingForm = this.formBuilder.group({
      month: new FormControl(''),
      slot: new FormControl(''),
    });
    this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=trekking${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        let startDate = data.slots.map(x => x.split(' - ')[0]);
        data.slots = data.slots.map(x => this.formatDateRangeService.formatDateRange(x));
        
        data.slots.sort((a, b) => {
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const partsA = a.split(' ');
          const dayA = parseInt(partsA[0].replace(/\D/g, ''), 10);
          const monthA = partsA[1];
          const partsB = b.split(' ');
          const dayB = parseInt(partsB[0].replace(/\D/g, ''), 10);
          const monthB = partsB[1];
          if (months.indexOf(monthA) === months.indexOf(monthB)) {
            return dayA - dayB;
          }
          return months.indexOf(monthA) - months.indexOf(monthB);
        });
        
        const months = data.slots.map(dateRange => {
          const month = dateRange.split(' ')[1];
          return month;
        });
        this.months = [...new Set(months)];
          this.months.sort((a, b) => {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            return months.indexOf(a) - months.indexOf(b);
          });
        this.loadingMonths = false;
        data.slots.forEach(async(x:any, i)=>{
          try {
            this.http.get(environment.apiUrl + `/range/minpricebycategory?category=trekking&date=${startDate[i]}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((res: any) => {
              if (res.status === "SUCCESS") {
                data.slots[i]= x+ ' ₹'+ res.min_price;
              }
            });
          } catch (error) {
            console.log(error)
          }
        })
        this.allSlots = data.slots;
        this.slots = data.slots;
      }
    })
  }

  FormTour() {
    this.isSubmittingBikeTour = false;
    this.biketourForm = this.formBuilder.group({
      month: new FormControl(''),
      slot: new FormControl(''),
      // variations: this.formBuilder.array([])
    });
    this.loadingMonths = true;
    this.http.get(environment.apiUrl + `/merchantactivities/packagetourslots?category=tour${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        let startDate = data.slots.map(x => x.split(' - ')[0]);
        data.slots = data.slots.map(x => this.formatDateRangeService.formatDateRange(x));
        data.slots.sort((a, b) => {
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const partsA = a.split(' ');
          const dayA = parseInt(partsA[0].replace(/\D/g, ''), 10);
          const monthA = partsA[1];
          const partsB = b.split(' ');
          const dayB = parseInt(partsB[0].replace(/\D/g, ''), 10);
          const monthB = partsB[1];
          if (months.indexOf(monthA) === months.indexOf(monthB)) {
            return dayA - dayB;
          }
          return months.indexOf(monthA) - months.indexOf(monthB);
        });
        const months = data.slots.map(dateRange => {
          const month = dateRange.split(' ')[1];
          return month;
        });
        this.months = [...new Set(months)];
          this.months.sort((a, b) => {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return months.indexOf(a) - months.indexOf(b);
          });
        this.loadingMonths = false;
        data.slots.forEach(async(x:any, i)=>{
          try {
            this.http.get(environment.apiUrl + `/range/minpricebycategory?category=tour&date=${startDate[i]}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((res: any) => {
              if (res.status === "SUCCESS") {
                data.slots[i]= x+ ' ₹'+ res.min_price;
              }
            });
          } catch (error) {
            console.log(error)
          }
        })
        this.allSlots = data.slots;
        this.slots = data.slots;
      }
    })
  }

  FormBikeRentals(){
    this.isSubmittingBikerental = false;
    this.rentalForm = this.formBuilder.group({
      activityId: new FormControl(''),
      date: new FormControl(''),
      time: new FormControl(''),
      dropdowndate: new FormControl(''),
      dropdowntime: new FormControl(''),
      guest: new FormControl('1', [Validators.required, Validators.pattern(/^[1-9]*$/)]),
      variations: this.formBuilder.array([]),
      inventoryvariations: this.formBuilder.array([])
    });
    this.currentDate = this.getCurrentDate();
    this.currentDate1 = this.getNextDate();
    try {
      this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${this.alignDate(this.currentDate)}${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe((data: any) => {
        if (data.status === "SUCCESS") {
          if(Number(data.min_price) == 0){
            this.currentDate = "";
            this.currentDate1 = "";
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
    if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
      let userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.value && userData.category === 'bikerental') {
        this.rentalForm.setValue({
          activityId: userData.value.activityId,
          date: userData.value.date,
          time: userData.value.time,
          dropdowndate: userData.value.dropdowndate,
          dropdowntime: userData.value.dropdowntime,
          guest: userData.value.guest,
          variations: [],
          inventoryvariations: []
        });
        this.currentDate = userData.value.date;
        this.dropDate = userData.value.dropdowndate;
      } else {
        sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
      }

    } else {
      sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
    }
    this.variationid = [];
    this.variationValueId = [];
    this.inventoryVariations = [];
    this.inventoryVariationValueIds = [];
    this.inventoryVariationValues = [];
    this.variationValues = [];
    this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        data.activityVariationDetails.forEach((x: any) => {
          x.merchants.forEach((y: any) => {
            if (!this.variationid.includes(y.variationtypeid)) {
              this.variationid.push(y.variationtypeid);
            }
          })
        })
        if (this.variationid.length > 0) {
          let defaultVariationsData = [], sortedDefaultVariationsData = [];
          for (let i = 0; i < this.variationid.length; i++) {
            const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${this.variationid[i]}`).toPromise();
            defaultVariationsData.push(data.variations)
          }
          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation == '0' || a.parentvariation == null) {
                return -1;
              } else if (b.parentvariation == '0' || b.parentvariation == null) {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          sortedDefaultVariationsData = sortByParentVariation(defaultVariationsData);
          const customSort = (a, b) => {
            const aIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === a.id);
            const bIdInAnyParentVariation = sortedDefaultVariationsData.some(item => item.parentvariation === b.id);

            if ((a.parentvariation == "0" || a.parentvariation == null) && !aIdInAnyParentVariation) {
              if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
                return 0; // Both have parentvariation as 0 or null, and id does not exist in any parentvariation, no change in order
              } else {
                return 1; // Only a has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
              }
            } else if ((b.parentvariation == "0" || b.parentvariation == null) && !bIdInAnyParentVariation) {
              return -1; // Only b has parentvariation as 0 or null, and id does not exist in any parentvariation, bring it to the end
            } else {
              return a.parentvariation.localeCompare(b.parentvariation);
            }
          }

          sortedDefaultVariationsData = sortedDefaultVariationsData.sort(customSort);
          let sortedDefaultVariationsid = [];
          for (let i = 0; i < sortedDefaultVariationsData.length; i++) {
            sortedDefaultVariationsid.push(sortedDefaultVariationsData[i].id);
          }
          this.variationid = sortedDefaultVariationsid;
          data.activityVariationDetails.forEach((x: any) => {
            x.merchants.forEach((y: any) => {
              let index = this.variationid.indexOf(y.variationtypeid.toString());
              if (index != -1) {
                if (this.variationValueId[index] != undefined) {
                  (!this.variationValueId[index].includes(y.variationvalueid.toString())) ? this.variationValueId[index].push(y.variationvalueid.toString()) : ""
                } else {
                  this.variationValueId[index] = [];
                  this.variationValueId[index].push(y.variationvalueid.toString())
                }
              }
            })
          })
          const variationsPromises = this.variationid.map(async (x: any) => {
            try {
              const data: any = await this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise();
              return data;
            } catch (error) {
              throw error;
            }
          });

          try {
            this.variations = await Promise.all(variationsPromises);
            for (let i = 0; i < this.variations.length; i++) {
              if (this.variations[i].variations.parentvariation == 0 || this.variations[i].variations.parentvariation == null) {
                const variationValue = this.variationValueId[i].map(async (y: any) => {
                  try {
                    const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
                    return data;
                  } catch (error) {
                    throw error;
                  }
                });
                let values = await Promise.all(variationValue)
                this.variationValues.push(values)
              } else {
                const variationValue = this.variationValueId[i].map(async (y: any) => {
                  try {
                    const data: any = await this.http.get(environment.apiUrl + `/variationvalues/get?id=${y}`).toPromise();
                    return data;
                  } catch (error) {
                    throw error;
                  }
                });
                let values = await Promise.all(variationValue)
                this.variationValues.push(values);
              }
            }
            const variationArray = this.rentalForm.get('variations') as FormArray;
            this.variations.forEach((x: any) => {
              variationArray.push(
                this.formBuilder.group({
                  variationid: new FormControl(x.variations.id),
                  type: new FormControl(x.variations.type),
                  variationvalueid: new FormControl('', Validators.required),
                  parentvariation: new FormControl(x.variations.parentvariation)
                }))
            })
            if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
              let userData = JSON.parse(sessionStorage.getItem('userData'));
              if (userData.value && userData.category === 'bikerental') {
                const variationArray = this.rentalForm.get('variations') as FormArray;
                if (userData.value.variations) {
                  userData.value.variations.forEach((x: any) => {
                    let indexToUpdate = variationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                    const variationGroupToUpdate = variationArray.at(indexToUpdate) as FormGroup;
                    variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
                    setTimeout(() => {
                      this.changeRentalDefaultVariation(indexToUpdate)
                    })
                  })
                }
              } else {
                sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
              }
            } else {
              sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }

      }
    })
    this.http.get(environment.apiUrl + `/merchantinventories/viewInventory?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}`).subscribe(async (data: any) => {
      if (data.status === "SUCCESS") {
        if (data.merchantinventories.length > 0) {
          let inventoryVariationIds = [];
          data.merchantinventories.forEach((x: any) => {
            if (!inventoryVariationIds.includes(x.variationid)) inventoryVariationIds.push(x.variationid);
            this.inventoryVariationValueIds.push(x.variationvalueid);
          })
          const inventoryVariationRequests = inventoryVariationIds.map((x: any) => {
            return this.http.get(environment.apiUrl + `/variations/get?id=${x}`).toPromise(); // Convert the Observable to a Promise
          });

          const loadVariationRequests = await Promise.all(inventoryVariationRequests);
          loadVariationRequests.forEach((x: any) => {
            this.inventoryVariations.push(x.variations)
          })

          const sortByParentVariation = (data) => {
            const sortedData = [...data];
            sortedData.sort((a, b) => {
              if (a.parentvariation === '0') {
                return -1;
              } else if (b.parentvariation === '0') {
                return 1;
              } else {
                return a.parentvariation.localeCompare(b.parentvariation);
              }
            });
            return sortedData;
          };
          this.inventoryVariations = sortByParentVariation(this.inventoryVariations);
          const inventoryvariationsArray = this.rentalForm.get('inventoryvariations') as FormArray;
          this.inventoryVariations.forEach((x: any) => {
            inventoryvariationsArray.push(
              this.formBuilder.group({
                variationid: new FormControl(x.id),
                type: new FormControl(x.type),
                variationvalueid: new FormControl(''),
              }))
          })
          if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
            let userData = JSON.parse(sessionStorage.getItem('userData'));
            if (userData.value && userData.category === 'bikerental') {
              const inventoryvariationArray = this.rentalForm.get('inventoryvariations') as FormArray;
              if (userData.value.variations) {
                userData.value.variations.forEach((x: any) => {
                  let indexToUpdate = inventoryvariationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                  const variationGroupToUpdate = inventoryvariationArray.at(indexToUpdate) as FormGroup;
                  variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
                })
              }
            } else {
              sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
            }
          } else {
            sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
          }
        }
        this.loadmerchantBikes(0);
      }
    })
    
  }


  async loadmerchantBikes(merchantid: any) {
    const inventoryvariationArray: any = this.rentalForm.get('inventoryvariations') as FormArray;
    inventoryvariationArray.clear();
    this.inventoryVariations = [];
    this.inventoryVariationValueIds = [];
    let activityVariation: any = await this.http.get(environment.apiUrl + `/merchantactivities/activityVariationDetails?category=bikerental${(this.locationId != null) ? '&locationId=' + this.locationId : ''}${(merchantid != 0) ? '&merchantid=' + merchantid : ''}`).toPromise();
    if (activityVariation.status === "SUCCESS" && activityVariation.activityVariationDetails.length > 0) {
      let start_time = [], end_time = [];
      start_time.push(this.timings.indexOf(activityVariation.activityVariationDetails[0].timefrom));
      end_time.push(this.timings.indexOf(activityVariation.activityVariationDetails[0].timeto));
      let selected_start_time = this.rentalForm.value.time;
      let selected_end_time = this.rentalForm.value.dropdowntime;

      let minNumber = Math.min(...start_time);
      let maxNumber = Math.max(...end_time);
      this.newTimings = [];
      for (let i = minNumber; i <= maxNumber; i++) {
        this.newTimings.push(this.timings[i]);
      }
      if(!this.newTimings.includes(selected_start_time)){
        this.rentalForm.get('time')?.setValue('');
      }
      if(!this.newTimings.includes(selected_end_time)){
        this.rentalForm.get('dropdowntime')?.setValue('');
      }
      }
  }

  async changeRentalDefaultVariation(column: any) {
    let variationid = this.rentalForm.value.variations[column]?.variationid;
    let parentVariation = this.rentalForm.value.variations[column + 1]?.parentvariation;
    let variationValueId = this.rentalForm.value.variations[column]?.variationvalueid;
    if (parentVariation == variationid) {
      const variationsArray = this.rentalForm.get('variations') as FormArray;
      const formGroup = variationsArray.at(column + 1) as FormGroup;
      if (formGroup) {
        formGroup.patchValue({
          variationvalueid: '',
        });
      }
      if (variationValueId != "") {
        let res: any = await this.http.post(environment.apiUrl + `/variationvalues/list`, { parentVariationvalueId: variationValueId, count: 500, status: 1 }).toPromise();
        if (res.status === "SUCCESS") {
          let values = [];
          res.variationvalues.forEach((x: any) => {
            if (this.variationValueId[column + 1]?.includes(x.id)) values.push({ status: "SUCCESS", variationvalues: x });
          })
          this.variationValues[column + 1] = values;
          this.onChangeRentalVariations();
        }
      } else {
        this.variationValues[column + 1] = [];
        this.onChangeRentalVariations();
      }
      if (formGroup) {
        if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
          let userData = JSON.parse(sessionStorage.getItem('userData'));
          if (userData.value && userData.category === 'bikerental') {
            const inventoryvariationArray = this.rentalForm.get('inventoryvariations') as FormArray;
            if (userData.value.variations) {
              userData.value.variations.forEach((x: any) => {
                let indexToUpdate = inventoryvariationArray.value.findIndex((y: any) => y.variationid == x.variationid);
                const variationGroupToUpdate = inventoryvariationArray.at(indexToUpdate) as FormGroup;
                variationGroupToUpdate.controls['variationvalueid'].setValue(x.variationvalueid);
              })
            }
          } else {
            sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
          }
        } else {
          sessionStorage.setItem('userData', JSON.stringify({ category: 'bikerental', value: this.rentalForm.value }))
        }
      }
    } else {
      this.onChangeRentalVariations();
    }
  }

  async onChangeRentalVariations() {
    // if (this.rentalForm.invalid) {
    //   return;
    // }
    if (this.rentalForm.value.variations.length == 0) {
      return;
    }
    // this.rentalForm.get('time')?.setValue('');
    let filter: any = {};
    if (this.locationId != null) {
      filter.locationId = this.locationId;
    }
    let values: any = await this.http.post(environment.apiUrl + `/merchants/merchantcategory`, { ...filter, category: 'bikerental' }, { responseType: 'json' }).toPromise();
    if (values.status === "SUCCESS") {
      if (values.merchants.length > 0) {
        let activityId = values.merchants[0].activities.find((x: any) => x.activitystatus == 1 && x.activitiecategory == "bikerental").activityid;
        this.rentalForm.get('activityId')?.setValue(activityId);
      }
    }
    let data: any = {};
    data.activityId = this.rentalForm.value.activityId;
    data.variations = [];
    this.rentalForm.value.variations.forEach((x: any) => {
      data.variations.push({ variationtypeid: x.variationid, variationvalueid: x.variationvalueid })
    })
    if (this.locationId != null) {
      data.locationId = this.locationId;
    }
    this.http.post(environment.apiUrl + `/merchantactivities/filterMerchant`, data).subscribe(async (data: any) => {
      {
        if (data.status === "SUCCESS") {
          let start_time = [], end_time = [];
          data.merchantactivities.forEach((x: any, i: any) => {
            start_time.push(this.timings.indexOf(x.timefrom));
            end_time.push(this.timings.indexOf(x.timeto));
          })
          let selected_start_time = this.rentalForm.value.time;
          let selected_end_time = this.rentalForm.value.dropdowntime;

          let minNumber = Math.min(...start_time);
          let maxNumber = Math.max(...end_time);
          this.newTimings = [];
          for (let i = minNumber; i <= maxNumber; i++) {
            this.newTimings.push(this.timings[i]);
          }
          if(!this.newTimings.includes(selected_start_time)){
             this.rentalForm.get('time')?.setValue('');
          }
          if(!this.newTimings.includes(selected_end_time)){
            this.rentalForm.get('dropdowntime')?.setValue('');
          }
          // setTimeout(() => {
          //   if (sessionStorage.getItem('userData') != null && sessionStorage.getItem('userData') != undefined && sessionStorage.getItem('userData') != '') {
          //     let userData = JSON.parse(sessionStorage.getItem('userData'));
          //     if (userData.value && userData.category === 'bikerental') {
          //       if(this.rentalForm.value.time == ''){
          //          this.rentalForm.get('time')?.setValue(userData.value.time);
          //       }
          //       if(this.rentalForm.value.dropdowntime == ''){
          //         this.rentalForm.get('dropdowntime')?.setValue(userData.value.dropdowntime);
          //       }
          //     }
          //     this.searchRentalMerchants()
          //   }
          // })
        }
      }
    })

  }

  
  get rentalVariationsArrayControls() {
    return (this.rentalForm.get('variations') as FormArray).controls;
  }

  get rentalInventoryVariationsArrayControls() {
    return (this.rentalForm.get('inventoryvariations') as FormArray).controls;
  }

  FormTopAdventurists(){
    this.isSubmittingTopAdventurists = false;
    this.topAdventuristsForm = this.formBuilder.group({
      name: new FormControl(''),
    })
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  changeMonths() {
    if (this.active_category == "trekking") {
      this.trekkingForm.get('slot').setValue('');
      this.slots = this.allSlots.filter(slot => {
        const monthPart = slot.split(' ')[1];
        return monthPart == this.trekkingForm.value.month;
      });
    } else if (this.active_category == "tour") {
      this.biketourForm.get('slot').setValue('');
      this.slots = this.allSlots.filter(slot => {
        const monthPart = slot.split(' ')[1];
        return monthPart == this.biketourForm.value.month;
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(()=>{
    if (typeof $ !== 'undefined' && $.fn.datepicker) {
      // Use jQuery Datepicker
      $('.datepicker').datepicker({
        minDate: new Date(this.alignDate(this.startDate)),
        dateFormat: 'dd/mm/yy',
        changeMonth: true,
        changeYear: true,
        onSelect: (dateText: any) => {
          this.currentDate = dateText;
        }
      });
      $('.datepicker1').datepicker({
        minDate: new Date(this.alignDate(this.startDate)),
        dateFormat: 'dd/mm/yy',
        changeMonth: true,
        changeYear: true,
        onSelect: (dateText: any) => {
          this.dropDate = dateText;
        }
      });

      let content_this = this;
        $(".datepicker").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center;'><img src='/assets/loader.svg' width=25px alt='loader' /></span>");
                if(content_this.active_category == "rafting"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=rafting&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "bikerental"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "camping"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "tour"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=tour&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "trekking"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=trekking&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "activity"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=activity&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
            }

          });
        });
        $(".datepicker1").focus(function() {
          let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
          let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
          month = Number(month)+1;
          $(".ui-datepicker-calendar .ui-state-default").each(function() {
            if($(this).parent().hasClass('ui-state-disabled')){
              $(this).addClass('text-center');
              return
            }else if(!$(this).hasClass('data-taken')){
              $(this).addClass('data-taken');
              $(this).addClass('text-center');
              $(this).css('background','#FFFFFF');
              let day= $(this).attr('data-date');
                let date = `${year}-${(month.length == 1)? '0'+month : month}-${(day.length == 1)? '0'+day : day}`;
                if(content_this.active_category == "bikerental"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=bikerental&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
                if(content_this.active_category == "camping"){
                  try {
                    content_this.http.get(environment.apiUrl + `/range/minpricebycategory?category=camping&date=${date}${(content_this.locationId != null) ? '&locationId=' + content_this.locationId : ''}`).subscribe((data: any) => {
                      if (data.status === "SUCCESS") {
                        if(Number(data.min_price) != 0){
                          $(this).html($(this).attr('data-date') + "<br><span style='font-size:10px; text-align:center; color:#008D52'>₹ "+ Number(data.min_price).toLocaleString('en-US') +" </span>");
                        }else{
                          $(this).html($(this).attr('data-date')); $(this).parent().addClass("ui-state-disabled")
                        }
                      }
                    });
                  } catch (error) {
                    console.log(error)
                  }
                }
            }
          });
        });
    } else {
      console.error('jQuery or jQuery UI Datepicker not available');
    }
  },500)
  }


  getCurrentDate(): string {
    return this.datePipe.transform(new Date(), 'dd/MM/yyyy')!;
  }

  getNextDate(): string {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Adding 1 day to the current date
    return this.datePipe.transform(currentDate, 'dd/MM/yyyy')!;
  }


  alignDate(inputDate: any) {
    const [day, month, year] = inputDate.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  isPastDateTime(timing: string): boolean {
    const currentDateTime = moment();
    const selectedDateTime = moment(`${this.currentDate} ${timing}`, 'DD-MM-YYYY h:mm a');
    return selectedDateTime.isBefore(currentDateTime);
  }


  preventToggle(event: MouseEvent) {
    event.stopPropagation();
  }

  increaseAdultValue(dataFor: string) {
    if (dataFor == "rafting" && this.raftingAdultsCount < 9) {
      this.raftingAdultsCount++;
    }
  }

  decreaseAdultValue(dataFor: string) {
    if (dataFor == "rafting" && this.raftingAdultsCount > 1) {
      this.raftingAdultsCount--;
    }
  }

  get r(): { [key: string]: AbstractControl } {
    return this.raftingForm.controls;
  }


  toggleFilter(dataFrom: string) {
    if (dataFrom == "rafting") {
      this.rafting_filter_visible = !this.rafting_filter_visible;
    }
    if (dataFrom == "activity") {
      this.activity_filter_visible = !this.activity_filter_visible;
    }
    if(dataFrom == "camping"){
      this.camping_filter_visible = !this.camping_filter_visible
    }
    if(dataFrom == "tour"){
      this.biketour_filter_visible = !this.biketour_filter_visible
    }
    if(dataFrom == "trekking"){
      this.trekking_filter_visible = !this.trekking_filter_visible
    }
    if(dataFrom == "topadventurists"){
      this.topadventurists_filter_visible = !this.topadventurists_filter_visible
    }
    if(dataFrom == "bikerental"){
      this.bikerentals_filter_visible = !this.bikerentals_filter_visible
    }
  }

  submitRaftingMerchants() {
    this.searchRaftingMerchants();
    this.bottomSheetRef.dismiss();
  }

  searchRaftingMerchants() {
    this.isSubmittingRafting = true;
    let filter_ratings : any = [];
    const checkbox5:any = document.getElementById('mobile-filter-rafting-rating-5') as HTMLInputElement;
    const checkbox4:any = document.getElementById('mobile-filter-rafting-rating-4') as HTMLInputElement;
    const checkbox3:any = document.getElementById('mobile-filter-rafting-rating-3') as HTMLInputElement;
    const checkbox2:any = document.getElementById('mobile-filter-rafting-rating-2') as HTMLInputElement;
    const checkbox1:any = document.getElementById('mobile-filter-rafting-rating-1') as HTMLInputElement;

    if(checkbox5 && checkbox5.checked){
      filter_ratings.push(5);
    }
    if(checkbox4 && checkbox4.checked){
      filter_ratings.push(4);
    }
    if(checkbox3 && checkbox3.checked){
      filter_ratings.push(3);
    }
    if(checkbox2 && checkbox2.checked){
      filter_ratings.push(2);
    }
    if(checkbox1 && checkbox1.checked){
      filter_ratings.push(1);
    }

    this.activityFilterService.setSearchRaftingData({ isSubmittingRafting: true, searchRaftingData: this.raftingForm.value, selectedRaftingMinPrice: this.selectedRaftingMinPrice, selectedQuickActivitiesMaxPrice: this.selectedRaftingMaxPrice, filter_ratings: filter_ratings });
  }

  searchActivityMerchants() {
    this.submitquickActivities();
    this.bottomSheetRef.dismiss();
  }
  
  submitquickActivities() {
    this.isSubmittingActivities = true;
    let filter_ratings : any = [];
    const checkbox5:any = document.getElementById('mobile-filter-quickactivities-rating-5') as HTMLInputElement;
    const checkbox4:any = document.getElementById('mobile-filter-quickactivities-rating-4') as HTMLInputElement;
    const checkbox3:any = document.getElementById('mobile-filter-quickactivities-rating-3') as HTMLInputElement;
    const checkbox2:any = document.getElementById('mobile-filter-quickactivities-rating-2') as HTMLInputElement;
    const checkbox1:any = document.getElementById('mobile-filter-quickactivities-rating-1') as HTMLInputElement;

    if(checkbox5 && checkbox5.checked){
      filter_ratings.push(5);
    }
    if(checkbox4 && checkbox4.checked){
      filter_ratings.push(4);
    }
    if(checkbox3 && checkbox3.checked){
      filter_ratings.push(3);
    }
    if(checkbox2 && checkbox2.checked){
      filter_ratings.push(2);
    }
    if(checkbox1 && checkbox1.checked){
      filter_ratings.push(1);
    }

    this.activityFilterService.setSearchActivityData({ isSubmittingActivities: true, searchActivityData: this.activitiesForm.value, selectedQuickActivitiesMinPrice: this.selectedQuickActivitiesMinPrice, selectedQuickActivitiesMaxPrice: this.selectedQuickActivitiesMaxPrice, filter_ratings: filter_ratings });
  }

  submitCampingFilter(){
    this.searchCamping();
    this.bottomSheetRef.dismiss();
  }

  searchCamping(){
    this.isSubmittingCamping = true;
    let filter_ratings : any = [];
    const checkbox5:any = document.getElementById('mobile-filter-camping-rating-5') as HTMLInputElement;
    const checkbox4:any = document.getElementById('mobile-filter-camping-rating-4') as HTMLInputElement;
    const checkbox3:any = document.getElementById('mobile-filter-camping-rating-3') as HTMLInputElement;
    const checkbox2:any = document.getElementById('mobile-filter-camping-rating-2') as HTMLInputElement;
    const checkbox1:any = document.getElementById('mobile-filter-camping-rating-1') as HTMLInputElement;

    if(checkbox5 && checkbox5.checked){
      filter_ratings.push(5);
    }
    if(checkbox4 && checkbox4.checked){
      filter_ratings.push(4);
    }
    if(checkbox3 && checkbox3.checked){
      filter_ratings.push(3);
    }
    if(checkbox2 && checkbox2.checked){
      filter_ratings.push(2);
    }
    if(checkbox1 && checkbox1.checked){
      filter_ratings.push(1);
    }

    this.activityFilterService.setSearchCampingData({ isSubmittingCamping: true, searchCampingData : this.campingForm.value, selectedCampingMinPrice: this.selectedCampingMinPrice, selectedCampingMaxPrice: this.selectedCampingMaxPrice, filter_ratings: filter_ratings, totalGuest: this.totalGuest});
  
  }

  submitTrekkingFilter(){
    this.submitTrekking();
    this.bottomSheetRef.dismiss();
  }

  submitTrekking(){
    this.isSubmittingTrekking = true;
    let filter_ratings : any = [];
    const checkbox5:any = document.getElementById('mobile-filter-trekking-rating-5') as HTMLInputElement;
    const checkbox4:any = document.getElementById('mobile-filter-trekking-rating-4') as HTMLInputElement;
    const checkbox3:any = document.getElementById('mobile-filter-trekking-rating-3') as HTMLInputElement;
    const checkbox2:any = document.getElementById('mobile-filter-trekking-rating-2') as HTMLInputElement;
    const checkbox1:any = document.getElementById('mobile-filter-trekking-rating-1') as HTMLInputElement;

    if(checkbox5 && checkbox5.checked){
      filter_ratings.push(5);
    }
    if(checkbox4 && checkbox4.checked){
      filter_ratings.push(4);
    }
    if(checkbox3 && checkbox3.checked){
      filter_ratings.push(3);
    }
    if(checkbox2 && checkbox2.checked){
      filter_ratings.push(2);
    }
    if(checkbox1 && checkbox1.checked){
      filter_ratings.push(1);
    }

    this.activityFilterService.setSearchTrekkingData({ isSubmittingTrekking: true, searchTrekkingData: this.trekkingForm.value, selectedTrekkingMinPrice: this.selectedTrekkingMinPrice, selectedTrekkingMaxPrice: this.selectedTrekkingMaxPrice, filter_ratings: filter_ratings });
  
  }

  submitTourFilter(){
    this.submitTour();
    this.bottomSheetRef.dismiss();
  }

  submitTour(){
    this.isSubmittingBikeTour = true;
    let filter_ratings : any = [];
    const checkbox5:any = document.getElementById('mobile-filter-tour-rating-5') as HTMLInputElement;
    const checkbox4:any = document.getElementById('mobile-filter-tour-rating-4') as HTMLInputElement;
    const checkbox3:any = document.getElementById('mobile-filter-tour-rating-3') as HTMLInputElement;
    const checkbox2:any = document.getElementById('mobile-filter-tour-rating-2') as HTMLInputElement;
    const checkbox1:any = document.getElementById('mobile-filter-tour-rating-1') as HTMLInputElement;

    if(checkbox5 && checkbox5.checked){
      filter_ratings.push(5);
    }
    if(checkbox4 && checkbox4.checked){
      filter_ratings.push(4);
    }
    if(checkbox3 && checkbox3.checked){
      filter_ratings.push(3);
    }
    if(checkbox2 && checkbox2.checked){
      filter_ratings.push(2);
    }
    if(checkbox1 && checkbox1.checked){
      filter_ratings.push(1);
    }

    this.activityFilterService.setSearchTourData({ isSubmittingBikeTour: true, searchTourData: this.biketourForm.value, selectedTourMinPrice: this.selectedBikeTourMinPrice, selectedTourMaxPrice: this.selectedBikeTourMaxPrice, filter_ratings: filter_ratings });
  }

  submitTopAdventuristsFilter(){
    this.submitTopAdventurists();
    this.bottomSheetRef.dismiss();
  }

  submitTopAdventurists() {
    this.isSubmittingTopAdventurists = true;
    let filter_ratings: any = [];
    const checkbox5: any = document.getElementById('mobile-filter-topadventurists-rating-5') as HTMLInputElement;
    const checkbox4: any = document.getElementById('mobile-filter-topadventurists-rating-4') as HTMLInputElement;
    const checkbox3: any = document.getElementById('mobile-filter-topadventurists-rating-3') as HTMLInputElement;
    const checkbox2: any = document.getElementById('mobile-filter-topadventurists-rating-2') as HTMLInputElement;
    const checkbox1: any = document.getElementById('mobile-filter-topadventurists-rating-1') as HTMLInputElement;
    if (checkbox5 && checkbox5.checked) {
      filter_ratings.push(5);
    }
    if (checkbox4 && checkbox4.checked) {
      filter_ratings.push(4);
    }
    if (checkbox3 && checkbox3.checked) {
      filter_ratings.push(3);
    }
    if (checkbox2 && checkbox2.checked) {
      filter_ratings.push(2);
    }
    if (checkbox1 && checkbox1.checked) {
      filter_ratings.push(1);
    }
    this.activityFilterService.setSearchTopAdventuristsData({ isSubmittingTopAdventurists: true, searchTopAdventuristsData: this.topAdventuristsForm.value, filter_ratings: filter_ratings });
  }

  submitBikeRentalsFilter(){
    this.submitBikeRentals();
    this.bottomSheetRef.dismiss();
  }

  submitBikeRentals(){
    this.isSubmittingBikerental = true;
    this.activityFilterService.setSearchBikeRentalData({ isSubmittingBikerental: true, searchBikeRentalData: this.rentalForm.value, selectedBikeRentalsMinPrice: this.selectedBikeRentalsMinPrice, selectedBikeRentalsMaxPrice: this.selectedBikeRentalsMaxPrice, });
  }

  submitCategoryFilter(){
    let filter_ratings: any = [];
    const checkbox5: any = document.getElementById('mobile-filter-category-rating-5') as HTMLInputElement;
    const checkbox4: any = document.getElementById('mobile-filter-category-rating-4') as HTMLInputElement;
    const checkbox3: any = document.getElementById('mobile-filter-category-rating-3') as HTMLInputElement;
    const checkbox2: any = document.getElementById('mobile-filter-category-rating-2') as HTMLInputElement;
    const checkbox1: any = document.getElementById('mobile-filter-category-rating-1') as HTMLInputElement;

    if (checkbox5 && checkbox5.checked) {
      filter_ratings.push(5);
    }
    if (checkbox4 && checkbox4.checked) {
      filter_ratings.push(4);
    }
    if (checkbox3 && checkbox3.checked) {
      filter_ratings.push(3);
    }
    if (checkbox2 && checkbox2.checked) {
      filter_ratings.push(2);
    }
    if (checkbox1 && checkbox1.checked) {
      filter_ratings.push(1);
    }

    this.activityFilterService.setSearchCategoryFilterData({ isSubmittingActivities: true,selectedCategoryFilterMinPrice: this.selectedCategoryMinPrice, selectedCategoryFilterMaxPrice: this.selectedCategoryMaxPrice, filter_ratings: filter_ratings });
  }

  get campingVariationsArrayControls() {
    return (this.campingForm.get('variations') as FormArray).controls;
  }

  changeRangePrice(dataFrom:any, event:any){
    if(dataFrom == 'rafting-min-price'){
      this.selectedRaftingMinPrice = event.target.value;
      this.searchRaftingMerchants();
    }
    if(dataFrom == 'rafting-max-price'){
      this.selectedRaftingMaxPrice = event.target.value;
      this.searchRaftingMerchants();
    }
    if(dataFrom == 'quickactivities-min-price'){
      this.selectedQuickActivitiesMinPrice = event.target.value;
      this.submitquickActivities();
    }
    if(dataFrom == 'quickactivities-max-price'){
      this.selectedQuickActivitiesMaxPrice = event.target.value;
      this.submitquickActivities();
    }
    if(dataFrom == 'camping-min-price'){
      this.selectedCampingMinPrice = event.target.value;
      this.searchCamping();
    }
    if(dataFrom == 'camping-max-price'){
      this.selectedCampingMaxPrice = event.target.value;
      this.searchCamping();
    }
    if(dataFrom == "trekking-min-price"){
      this.selectedTrekkingMinPrice = event.target.value;
      this.submitTrekking();
    }
    if(dataFrom == "trekking-max-price"){
      this.selectedTrekkingMaxPrice = event.target.value;
      this.submitTrekking();
    }
    if(dataFrom == "tour-min-price"){
      this.selectedBikeTourMinPrice = event.target.value;
      this.submitTour();
    }
    if(dataFrom == "tour-max-price"){
      this.selectedBikeTourMaxPrice = event.target.value;
      this.submitTour();
    }
    if(dataFrom == "bikerentals-min-price"){
      this.selectedBikeRentalsMinPrice = event.target.value;
      this.submitBikeRentals();
    }
    if(dataFrom == "bikerentals-max-price"){
      this.selectedBikeRentalsMaxPrice = event.target.value;
      this.submitBikeRentals();
    }
    if (dataFrom == "category-min-price") {
      this.submitCategoryFilter = event.target.value;
      this.submitCategoryFilter();
    }
    if (dataFrom == "category-max-price") {
      this.selectedCategoryMaxPrice = event.target.value;
      this.submitCategoryFilter();
    }
  }
  
  increaseValue(type: any) {
    if (type == "adult" && this.adultsCount < 10) {
      this.adultsCount++;
      this.totalGuest++;
    }
    if (type == "children" && this.childrenCount < 10) {
      this.childrenCount++;
      this.totalGuest++;
    }
  }

  decreaseValue(type: any) {
    if (type == "adult" && this.adultsCount > 1) {
      this.adultsCount--;
      this.totalGuest--;
    }
    if (type == "children" && this.childrenCount > 0) {
      this.childrenCount--;
      this.totalGuest--;
    }
  }
}
