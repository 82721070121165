<div mat-dialog-content class="overflow-auto h-100 p-0">
  <div class=" mb-0 p-2 pb-md-0 ps-md-3 connect-container">
    <div class="d-flex m-md-4 mb-0 m-2">
      <div class="p-0" style="width: calc(100% - 1em);">
        <h1 class="fw-bold default-font-family title mb-0 justify-content-start">
          Coupons
        </h1>
      </div>
      <div class=" p-0">
        <button type="button" class="btn-close  justify-content-end" (click)="closeCoupon()" data-dismiss="modal"
          aria-label="Close"></button>
      </div>
    </div>
  </div>

  <div class="px-md-3 px-2">
    <div class="row shadow py-3 m-md-3 p-md-2  mx-1 pe-1 my-3" *ngFor="let data of  couponsData,let i= index">
      <div class="col-9  default-font-family p-md-2 pe-0 pe-md-0">
        <div class="d-flex">
          <div class="discount-offer  pe-3" *ngIf="data.discountpercentage && data.discountpercentage != ''">
            {{data.discountpercentage}}% OFF</div>
            <div class="discount-offer  pe-3" *ngIf="data.discountamount && data.discountamount != ''">
              ₹ {{data.discountamount}} OFF</div>
          <div class="coupon-input text-start  ">
            <div class="offer-details px-1" style="  font-size: 11px;">{{ data.code }}</div>
          </div>
        </div>
        <div class="pt-3" style="width: calc(100% - 8px);">
          <div class="d-md-flex align-items-baseline ">
            <h3 class="offer-details mb-0 me-md-3 "
              *ngIf="data.validforusertype !== undefined && data.validforusertype!== ''">*{{ (data.validforusertype === 'register' ? 'registered' : data.validforusertype) | titlecase  }}
              users are eligible </h3>
            <h3 class="offer-details mb-0 " *ngIf="data.validto !== undefined && data.validto!== ''">*Offer valid till
              {{this.validto}} </h3>
          </div>
          <div class="d-md-flex align-items-baseline ">
            <h3 class="offer-details mb-0 me-md-3" *ngIf="data.maxdiscount !== undefined && data.maxdiscount!== ''">
              *Maximum discount upto Rs. {{data.maxdiscount}}
            </h3>
            <h3 class="offer-details mb-0 " *ngIf="data.validforfirst !== undefined && data.validforfirst!== ''"> {{
              getValidForFirstMessage(data.validforfirst) }}</h3>
          </div>
          <h3 class="offer-details mb-0" *ngIf="data.validforminamount !== undefined && data.validforminamount!== ''">
            *Valid on the net transaction amount of Rs. {{data.validforminamount}} </h3>
            <h3 class="offer-details mb-0" *ngIf="data.discountpercentage !== undefined && data.discountpercentage != null && data.discountpercentage!== ''">
              *Maximum discount upto {{data.discountpercentage}}% </h3>
              <h3 class="offer-details mb-0" *ngIf="data.discountamount != undefined && data.discountamount != null && data.discountamount!= ''">
                *Maximum discount upto  Rs. {{data.discountamount}}</h3>
        </div>
      </div>
      <div class="name col-3 text-md-start text-end pointer px-0 pe-md-0 pe-1" (click)="apply(data.id)">APPLY COUPON
      </div>
    </div>
  </div>
</div>